import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import axios from 'axios';
import edusim from '../config/edusim';
import Skeleton from '@material-ui/lab/Skeleton';
export default function Requests(props) {
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [requests, setRequests] = React.useState([]);
  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;
  const propsUser = props.user;
  
  React.useEffect(()=>{

    isMounted.current = true;

    const refreshRequests = () =>{
        setLoading(true);
        setTimeout(()=> {
            axios.post(edusim.api_base_uri+"/api/parents/getRequests", {},{
                headers: {
                  'x-access-token': propsUser.token
                }
              }).then(res => {
                setRequests(res.data);
                setLoading(false);
              }).catch(e => {
                if(e.response){
                    if(isMounted.current){
                        setLoading(false);
                    }
                    if(e.response.status === 403){
                        propsUpdateUser({});
                    }
                }else{
                    if(isMounted.current){
                        setLoading(false);
                        setError("Network connection might be lost, ")
                    }                    
                }
                //console.log(e);
              });
        }, 200);
        
    }

    
    refreshRequests();

    return () => {
        // executed when unmount
        isMounted.current = false;
    }

  },[propsUpdateUser, propsUser]);

  React.useEffect(()=>{
    setTimeout(()=>{
      if(error !== ''){
        setError('');
      }
    }, 3000);
  },[error]);

  return (
    <React.Fragment>
      <Title>Requests</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Requested On</TableCell>
            <TableCell>Request Type</TableCell>
            <TableCell>Request Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(loading ? Array.from(new Array(3)):requests).map((row, key) => (
            row?
            <TableRow key={row._id}>
              <TableCell>{row.requestedOn.toString().substring(0,10)}</TableCell>
              <TableCell>{row.type}</TableCell>
              <TableCell>{row.status}</TableCell>
            </TableRow>
            :
            <TableRow key={key}>
            <TableCell><Skeleton variant="rect" width={100} height={30} /></TableCell>
            <TableCell><Skeleton variant="rect" width={100} height={30} /></TableCell>
            <TableCell><Skeleton variant="rect" width={100} height={30} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
     
    </React.Fragment>
  );
}
