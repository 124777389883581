import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
  InputAdornment,
  Typography,
  IconButton,
  Badge,
  Popover,
  OutlinedInput,
  Grid,
  Box,
  Avatar,
  Container,
  CircularProgress
} from "@material-ui/core"

import {
  Inbox as InboxIcon,
  EmojiEmotionsOutlined as EmojiEmotionsOutlinedIcon,
  Send as SendIcon,

} from "@material-ui/icons";

import useSocket from 'use-socket.io-client';

import edusim from '../config/edusim';

import Picker, { SKIN_TONE_NEUTRAL } from 'emoji-picker-react';

import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  ownMessage: { 
    width: '60%', 
    border:"solid 1px #cccccc", 
    marginBottom: "20px", 
    borderRadius: 10, 
    alignSelf:"flex-end"
  },
  otherMessage: { 
    width: '60%', 
    border:"solid 1px #cccccc", 
    marginBottom: "20px", 
    borderRadius: 10, 
    alignSelf:"flex-start"
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  studentAvatar:{
    backgroundColor: 'transparent'
  },
  wrapper: {
    position: 'relative',
  },
  inButtonProgress: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
  },
  uploadButton: {
    width:'100%'
  },
  dropZoneClass:{
    maxHeight: '200px'
  },
  infoHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold
    /* flexBasis: '33.33%',
    flexShrink: 0, */

  },
  infoHeadingBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '25%',
    flexShrink: 0,
  },
  infoBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '75%',
    flexShrink: 0,
  },
  infoSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  enrollmentsListRoot:{
    width: '100%',
    maxWidth: '55ch',
    backgroundColor: theme.palette.background.paper,
  },
  accordianHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordianSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const EmojiPanel = React.memo((props) =>{
  const addEmojiToMessage = props.addEmojiToMessage;
  const onEmojiClick = (event, emojiObject) => {
    addEmojiToMessage(emojiObject.emoji);
    console.log(emojiObject);
  };
  return (
    <div>
      <Picker
        onEmojiClick={onEmojiClick}
        disableAutoFocus={true}
        skinTone={SKIN_TONE_NEUTRAL}
        groupNames={{ smileys_people: 'PEOPLE' }}
        native
      />
    </div>
  )
});

const RecentChatsList = (props) =>{
  const {loading, student, currentChatSections, recentChats, selectedChat, setSelectedChat, newMessageCount} = props
  
  const classes = useStyles();

  function getSectionName(_sectionid){
    return currentChatSections[currentChatSections.findIndex(s=>s._id===_sectionid)]._subjectid.name;
  }

  function getSectionInstructorName(_sectionid){
    return currentChatSections[currentChatSections.findIndex(s=>s._id===_sectionid)].instructorName;
  }

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>Recent Chats</Typography>
      {loading&&<CircularProgress />}
      <List component="nav" className={classes.enrollmentsListRoot}>

        {recentChats&&recentChats.map((chat, key)=>(
          <ListItem
          key={key}
          button
          selected={selectedChat&&(selectedChat._id===chat._id)}
          onClick={(event) => setSelectedChat(chat)}
           >
        
          <ListItemIcon>
            <Badge badgeContent={newMessageCount&&newMessageCount[chat._id]} color="secondary">
                <InboxIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText 
            primary={getSectionInstructorName(chat._sectionid)}
            secondary={getSectionName(chat._sectionid)} />
          </ListItem>
        ))}
        
        
    </List>
    </React.Fragment>
  )
}

export default function Messages(props) {
  const classes = useStyles();
  const scrollRef = React.useRef(null);
  const isMounted = React.useRef(null);
  const student = props.student;
  const studentImageSrc = props.studentImageSrc;
  const setStudentChatActive = props.setStudentChatActive;
  const [loading, setLoading] = React.useState(false);
  const [connected, setConnected] = React.useState(false);
  const [messages, setMessages] = React.useState({});
  const [newMessageCount, setNewMessagesCount] = React.useState({});
  const [newMessage, setNewMessage] = React.useState('');
  const [recentChats, setRecentChats] = React.useState([]);
  const [selectedChat, setSelectedChat] = React.useState(null);
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const apiUri = edusim.api_socket_uri;
  const [socket] = useSocket(apiUri+"/teacher-chat", {
    transports: ['websocket'],
    autoConnect: false,
  });
 
  const emojiOpen = Boolean(anchorEl);

  const propsUser = props.user;

  const currentChatSections = props.currentChatSections;
  
  React.useEffect(() => {
    
    socket.connect();
 
    isMounted.current = true;
    
    socket.on("disconnect",() => {
      if(isMounted){
        console.log('disconnected');
        setConnected(false);
      }
    });

    socket.on("connect",() => {
        if(isMounted){
        console.log('connect event');
        setConnected(true);
        let sectionIds = currentChatSections.map(({_id})=>_id);
        setLoading(true);
        axios.post(edusim.api_base_uri+"/api/parents/getStudentChats",{_studentid:student._id, sectionIds: sectionIds}, {
          headers: {
            'x-access-token': propsUser.token
          }}).then((res)=>{
            res.data.forEach((chat)=>{
              socket.emit('JoinRoom', chat._id);
              axios.get(edusim.api_base_uri+"/api/parents/getUnreadCount/"+chat._id,{
                headers: {
                    'x-access-token': propsUser.token
                }}).then((res)=>{
                    setNewMessagesCount(oldCountObj=> {
                        return {
                            ...oldCountObj,
                            [chat._id]: res.data.count
                        }
                    })
                });
            });
            res.data&&res.data.length>0&&setSelectedChat(res.data[0]);
            setRecentChats(res.data);
            
          }).finally(()=>setLoading(false));
      }
    });
    
    socket.on("ChatMessage", (msg)=>{
      console.log(msg);
      let newMsg = JSON.parse(msg);
      newMsg.timestamp = new Date();

      if(newMsg.message.from!==propsUser.username){
        setNewMessagesCount(oldMsgCounts=>{
            return {
              ...oldMsgCounts,
              [newMsg._studentchatid]: (oldMsgCounts[newMsg._studentchatid]+1)
            }
          })
      }
     
      setMessages(oldMsgs => {
        return {
          ...oldMsgs, 
          [newMsg._studentchatid]: oldMsgs[newMsg._studentchatid]?[...oldMsgs[newMsg._studentchatid], newMsg]:[newMsg]
        }
      });

      setRecentChats(oldChats => {
        let chatUpdatedIndex = oldChats.findIndex(c=>c._id===newMsg._studentchatid);
        let newChat = {...oldChats[chatUpdatedIndex]};
        newChat.timestamp=new Date();
        let otherChats = oldChats.filter(c=>c._id!==newChat._id);
        return [
          newChat,
          ...otherChats
        ]
      });

    });

    return(()=>{
      console.log('unmounted!!');
      isMounted.current = false;
      socket.close();
    });

  }, [propsUser, socket, student, currentChatSections]);

  React.useEffect(() => {
    selectedChat&&axios.get(edusim.api_base_uri+"/api/parents/students/"+student._id+"/messages/"+selectedChat._sectionid,{
      headers: {
        'x-access-token': propsUser.token
      }}).then((res)=>{
        let unreadMessageIds = []; 
        res.data.forEach((message)=>{
            !message.read&&(message.message.from!==propsUser.username)&&unreadMessageIds.push(message._id);
        });
        axios.post(edusim.api_base_uri+"/api/parents/readUnreadMessages", {unreadMessageIds: unreadMessageIds},{
            headers: {
              'x-access-token': propsUser.token
            }}).then((res)=>{});
        
        setMessages(oldMsg=> {return {...oldMsg, [selectedChat._id]: res.data}});
        setTimeout(()=>
            setNewMessagesCount(oldMsgCounts=>{
            return {
              ...oldMsgCounts,
              [selectedChat._id]: 0
            }
          }), 2000)
      });
  }, [selectedChat, propsUser, student]);

  React.useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: "smooth" });
    }
    selectedChat&&setTimeout(()=>
        setNewMessagesCount(oldMsgCounts=>{
        return {
            ...oldMsgCounts,
            [selectedChat._id]: 0
        }
    }), 1000)
    
  }, [messages, selectedChat]);

  const handleKeyPress = (e) =>{
    if (e.key === 'Enter')
      handleSendMessage();
  }
  
  const handleSendMessage = () =>{

    let sentMessage = {
      _studentchatid: selectedChat._id,
      message:{
          from: props.user.username,
          text: newMessage
      }
    }
    const roomId = selectedChat._id;
    socket.emit('ChatMessage', {roomId: roomId, sentMessage: JSON.stringify(sentMessage)});
    setNewMessage('');
  }

  const handleEmojiOpen = (event) =>{
    setAnchorEl(event.currentTarget);
  }

  const handleEmojiClose = (event) =>{
    setAnchorEl(null);
  }

  const addEmojiToMessage = (emoji) =>{
    setNewMessage(newMessage+emoji);
  }

  return (
    <React.Fragment>
      <Container maxWidth="xl" className={classes.container}>
      <Box display="flex" flexDirection="row" alignItems="center">
      <Box>
        {studentImageSrc&& <Avatar onClick={()=>setStudentChatActive(false)} className={classes.largeAvatar} src={studentImageSrc} />}
      </Box>
      <Box marginLeft={2}>
      {
        <h2 style={{padding:0, margin:0}}>{student.name}</h2>
      }
      </Box>
      {/* <Box marginLeft={2}>
      {  
        <Chip label={student.status} style={{backgroundColor:statusColors[student.status], color:"#ffffff"}} />
      }
      </Box> */}
     </Box>
      <Paper className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={3} xl={2}>
          <Paper className={classes.paper}>
            <RecentChatsList 
            loading={loading}
            student={student}
            currentChatSections={currentChatSections} 
            connected={connected} 
            newMessageCount={newMessageCount} 
            recentChats={recentChats} 
            selectedChat={selectedChat} 
            setSelectedChat={setSelectedChat} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          <Paper className={classes.paper}>
            <Box
              overflow="auto"
              maxHeight="78vh"
              flexDirection="column"
              display="flex"
            >
              <Box
                overflow="auto"
                flex={1}
                flexDirection="column"
                display="flex"
                p={2}
              >
                {selectedChat&&messages&&messages[selectedChat._id]&&messages[selectedChat._id].map((message, key)=>(
                    <List key={key} className={message.message.from===propsUser.username?classes.ownMessage:classes.otherMessage}>
                    <ListItem>
                        <ListItemText id={'id'} primary={message.message.text} secondary={message.message.from + " - " + message.timestamp.toString()} />
                    </ListItem>
                    </List>
                ))}
                <Box ref={scrollRef} />
                </Box>
              </Box>

              <Grid container>
                  <Grid item xs={12}>
                  <OutlinedInput
                      disabled={!connected}
                      variant="outlined"
                      onKeyPress={handleKeyPress}
                      fullWidth
                      value={newMessage?newMessage:''}
                      onChange={(e)=>setNewMessage(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            variant="contained"
                            disabled={!connected}
                            color="primary"
                            onClick={(e)=>handleSendMessage(e)}
                            edge="end">
                              <SendIcon />
                            </IconButton>
                            <IconButton
                            variant="contained"
                            disabled={!connected}
                            color="primary"
                            onClick={handleEmojiOpen}
                            edge="end">
                              <EmojiEmotionsOutlinedIcon />
                            </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  </Grid>
                  <Popover
                      open={emojiOpen}
                      anchorEl={anchorEl}
                      onClose={handleEmojiClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <EmojiPanel addEmojiToMessage={addEmojiToMessage} />
                  </Popover>
          </Paper>
        </Grid>
      </Grid>
      </Paper>
    </Container>
    </React.Fragment>
  );
}