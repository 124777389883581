import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';


import axios from 'axios';
import edusim from '../config/edusim';
import Skeleton from '@material-ui/lab/Skeleton';

import TextField from '@material-ui/core/TextField';

import localDataObjects from '../config/data';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import NativeSelect from '@material-ui/core/NativeSelect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';

import {DropzoneArea} from 'material-ui-dropzone';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DescIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import LinearProgress from '@material-ui/core/LinearProgress';

import Moment from 'moment';

import maleAvatarPlaceholder from '../imgs/maleAvatarPlaceholder.png';
import femaleAvatarPlaceholder from '../imgs/femaleAvatarPlaceholder.png';
import CircularProgress from '@material-ui/core/CircularProgress';

import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  studentAvatar:{
    backgroundColor: 'transparent'
  },
  wrapper: {
    position: 'relative',
  },
  inButtonProgress: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
  },
  uploadButton: {
    width:'100%'
  },
  dropZoneClass:{
    maxHeight: '200px'
  }
}));

const maleRelationsArray = ["Father", "Grandfather", "Guardian"];
const femaleRelationsArray = ["Mother", "Grandmother", "Guardian"];
const maritalStatusArray = ["Married", "Divorced", "Widowed"];
const bloodTypesArray = ["O-", "O+", "A-", "A+","B-","B+","AB-","AB+"];
const statusColors = edusim.admission_status_colors;

const GeneralInfoForm = ({loading,application,user, handleTextChange, handleSelect, errors, errorMessages}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        {loading? <Skeleton animation="wave" /> :
        <TextField
          required
          id="parentMobile"
          name="parentMobile"
          label="Parent Verified Mobile"
          fullWidth
          value={user.mobile? user.mobile : ''}
          disabled
        />}
      </Grid>
      <Grid item xs={12} sm={6}>
        {loading? <Skeleton animation="wave" /> :
        <TextField
          required
          id="parentEmail"
          name="parentEmail"
          label="Parent Verified Email"
          fullWidth
          value={user.username? user.username : ''}
          disabled
        />}
      </Grid>
      <Grid item xs={12} sm={6}>
        {loading? <Skeleton animation="wave" /> :
        <TextField
          required
          error={errors['parentFullName']? true: false}
          helperText={errorMessages['parentFullName']? errorMessages['parentFullName']: null}
          id="parentFullName"
          name="parentFullName"
          label="Parent Full Name"
          fullWidth
          autoComplete="name"
          key={34}
          onChange={(e) => handleTextChange(e, 'parentFullName')}
          defaultValue={application.guardians[0]._id.name? application.guardians[0]._id.name: user.guardian? user.guardian.name:''}
        />}
      </Grid>
      <Grid item xs={12} sm={3}>
        {loading? <Skeleton animation="wave" /> :
        <TextField
          error={errors['parentDOB']? true: false}
          helperText={errorMessages['parentDOB']? errorMessages['parentDOB']: null}
          id="parentDOB"
          name="parentDOB"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{max:'3000-12-12'}}
          label="Parent Birth Date"
          onChange={(e) => handleTextChange(e, 'parentDOB')}
          fullWidth
          defaultValue={application.guardians[0]._id.dateofbirth? application.guardians[0]._id.dateofbirth.slice(0, 10) : user.guardian? user.guardian.dateofbirth.slice(0,10):''}
        />}
      </Grid>
      <Grid item xs={12} sm={3}>
        {loading? <Skeleton animation="wave" /> :
           <FormControl fullWidth className={classes.formControl}>
           <InputLabel htmlFor="parentGender">Gender</InputLabel>
           <NativeSelect
             defaultValue={application.guardians[0]._id.gender? application.guardians[0]._id.gender : user.guardian? user.guardian.gender : 'male'}
             onChange={(e) => handleSelect(e, 'parentGender')}
             inputProps={{
               name: 'parentGender',
               id: 'parentGender',
             }}
           >
             <option value={'male'}>Male</option>
             <option value={'female'}>Female</option>
           </NativeSelect>
         </FormControl>}
      </Grid>

      <Grid item xs={12} sm={6}>
        {loading? <Skeleton animation="wave" /> :
        <TextField
          required
          error={errors['studentFullName']? true: false}
          helperText={errorMessages['studentFullName']? errorMessages['studentFullName']: null}
          id="studentFullName"
          name="studentFullName"
          label="Student Full Name"
          fullWidth
          autoComplete="name"
          key={34}
          onChange={(e) => handleTextChange(e, 'studentFullName')}
          defaultValue={application.student.name? application.student.name: ''}
        />}
      </Grid>
      <Grid item xs={12} sm={3}>
        {loading? <Skeleton animation="wave" /> :
        <TextField
          error={errors['studentDOB']? true: false}
          helperText={errorMessages['studentDOB']? errorMessages['studentDOB']: null}
          id="studentDOB"
          name="studentDOB"
          type="date"
          inputProps={{max:'3000-12-12'}}
          InputLabelProps={{
            shrink: true,
          }}
          label="Student Birth Date"
          onChange={(e) => handleTextChange(e, 'studentDOB')}
          fullWidth
          defaultValue={application.student.demographics.dateofbirth? application.student.demographics.dateofbirth.slice(0, 10) : ''}
        />}
      </Grid>
      <Grid item xs={12} sm={3}>
        {loading? <Skeleton animation="wave" /> :
           <FormControl fullWidth className={classes.formControl}>
           <InputLabel htmlFor="studentGender">Gender</InputLabel>
           <NativeSelect
             defaultValue={application.student.demographics.gender? application.student.demographics.gender : 'male'}
             onChange={(e) => handleSelect(e, 'studentGender')}
             inputProps={{
               name: 'studentGender',
               id: 'studentGender',
             }}
           >
             <option value={'male'}>Male</option>
             <option value={'female'}>Female</option>
           </NativeSelect>
         </FormControl>}
      </Grid>
      
    </Grid>
  )
}

const ParentInfoForm = ({loading,application,handleTextChange, handleSelect, handleAutoComplete, errors, errorMessages}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>      
      <Grid item xs={12} sm={3}>
        {loading? <Skeleton animation="wave" /> :
           <FormControl fullWidth className={classes.formControl}>
           <InputLabel htmlFor="parentStudentRelationship">Relationship To Student</InputLabel>
           <NativeSelect
             defaultValue={application.guardians[0].studentrelationship? application.guardians[0].studentrelationship : ''}
             onChange={(e) => handleSelect(e, 'parentStudentRelationship')}
             inputProps={{
               name: 'parentStudentRelationship',
               id: 'parentStudentRelationship',
             }}
           >
             {(application.guardians[0]._id.gender === 'male' ? maleRelationsArray : femaleRelationsArray).map((obj, key)=>(
              <option key={key} value={obj}>{obj}</option>
             ))}
           </NativeSelect>
         </FormControl>}
      </Grid>
      <Grid item xs={12} sm={3}>
        {loading? <Skeleton animation="wave" /> :
           <FormControl fullWidth className={classes.formControl}>
           <InputLabel htmlFor="parentMaritalStatus">Marital Status</InputLabel>
           <NativeSelect
             defaultValue={application.guardians[0]._id.maritalstatus? application.guardians[0]._id.maritalstatus : maritalStatusArray[0]}
             onChange={(e) => handleSelect(e, 'parentMaritalStatus')}
             inputProps={{
               name: 'parentMaritalStatus',
               id: 'parentMaritalStatus',
             }}
           >
             {(maritalStatusArray).map((obj, key)=>(
              <option key={key} value={obj}>{obj}</option>
             ))}
           </NativeSelect>
         </FormControl>}
      </Grid>
      <Grid item xs={12} sm={6}>
        {loading? <Skeleton animation="wave" /> :
        <Autocomplete
        id="parentNationality"
        options={localDataObjects.countries}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.Name? option.Name:''}
        renderOption={(option) => (
          <React.Fragment>
            {option.Name} ({option.Code})
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Parent Nationality"
            /* variant="outlined" */
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            //defaultValue={}
            fullWidth
            error={errors['parentNationality']? true: false}
            helperText={errorMessages['parentNationality']? errorMessages['parentNationality']: null}    
          />
        )}
        getOptionSelected={(option, value) => value.Code === option.Code}
        value={application.guardians[0]._id.nationality? application.guardians[0]._id.nationality : null}
        onChange={(e,newValue)=>handleAutoComplete(newValue, 'parentNationality')}
      />}
      </Grid>

      <Grid item xs={12} sm={3}>
        {loading? <Skeleton animation="wave" /> :
        <TextField
          required
          error={errors['parentNationalId']? true: false}
          helperText={errorMessages['parentNationalId']? errorMessages['parentNationalId']: null}
          id="parentNationalId"
          name="parentNationalId"
          label="National ID"
          fullWidth
          autoComplete="nationalId"
          key={35}
          onChange={(e) => handleTextChange(e, 'parentNationalId')}
          defaultValue={application.guardians[0]._id.nationalid? application.guardians[0]._id.nationalid: ''}
        />}
      </Grid>
      <Grid item xs={12} sm={3}>
        {loading? <Skeleton animation="wave" /> :
        <TextField
          required
          error={errors['parentPassportNumber']? true: false}
          helperText={errorMessages['parentPassportNumber']? errorMessages['parentPassportNumber']: null}
          id="parentPassportNumber"
          name="parentPassportNumber"
          label="Passport Number"
          fullWidth
          autoComplete="passportNumber"
          key={35}
          onChange={(e) => handleTextChange(e, 'parentPassportNumber')}
          defaultValue={application.guardians[0]._id.passportid? application.guardians[0]._id.passportid: ''}
        />}
      </Grid>
      <Grid item xs={12} sm={6}>
        {loading? <Skeleton animation="wave" /> :
        <TextField
          required
          error={errors['parentEmployerName']? true: false}
          helperText={errorMessages['parentEmployerName']? errorMessages['parentEmployerName']: null}
          id="parentEmployerName"
          name="parentEmployerName"
          label="Employer Name"
          fullWidth
          autoComplete="employerName"
          key={35}
          onChange={(e) => handleTextChange(e, 'parentEmployerName')}
          defaultValue={application.guardians[0]._id.employer? application.guardians[0]._id.employer: ''}
        />}
      </Grid>
      
      
    </Grid>
  )
}

const StudentInfoForm = ({loading,application,studentImageSrc, siUploadProgress, handleTextChange, handleSelect, handleAutoComplete, handleImageUpload,errors, errorMessages}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>      
      <Grid item xs={12} sm={12}>
        {loading? <Skeleton animation="wave" /> :
        <div>
        <h3 style={{marginTop:0, paddingTop:0}}>Upload {application.student.name}'s Official Picture</h3>
        <input
          accept="image/*"
          style={{display:'none'}}
          id="contained-button-file"
          type="file"
          onChange={(e)=>handleImageUpload(e, 'studentAvatar')}
        />
        <label htmlFor="contained-button-file">
         <Button style={{padding:0, borderRadius: 10, border:0}} component="span" aria-label="upload picture">
         <img
          style={{maxWidth: 200, borderRadius: 10, border: 0}}
          className={classes.studentAvatar}
          variant="rounded"
          src={studentImageSrc}
          onError= {(e) => { e.target.onerror=null;e.target.src = application.student.demographics.gender==='male'? maleAvatarPlaceholder:femaleAvatarPlaceholder; } }
          alt="Upload"
          description="Upload"
          />
          {(siUploadProgress > 0) && <CircularProgress variant="determinate" size={50} value={siUploadProgress} className={classes.inButtonProgress} />}
          </Button>
        </label>
          
        </div>}
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <h3 style={{marginTop:0, paddingTop:0}}>Applying for Grade/Level:</h3>
        <h1>{application.level}</h1>
      </Grid> */}
      <Grid item xs={12} sm={6}>
        {loading? <Skeleton animation="wave" /> :
        <Autocomplete
        id="studentNationality"
        options={localDataObjects.countries}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.Name? option.Name:''}
        renderOption={(option) => (
          <React.Fragment>
            {option.Name} ({option.Code})
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Student Nationality"
            /* variant="outlined" */
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off', // disable autocomplete and autofill
            }}
            //defaultValue={}
            fullWidth
            error={errors['studentNationality']? true: false}
            helperText={errorMessages['studentNationality']? errorMessages['studentNationality']: null}    
          />
        )}
        getOptionSelected={(option, value) => value.Code === option.Code}
        value={application.student.demographics.nationality? application.student.demographics.nationality : null}
        onChange={(e,newValue)=>handleAutoComplete(newValue, 'studentNationality')}
      />}
      </Grid>

      <Grid item xs={12} sm={6}>
        {loading? <Skeleton animation="wave" /> :
           <FormControl fullWidth className={classes.formControl}>
           <InputLabel htmlFor="studentBloodtype">Blood Type</InputLabel>
           <NativeSelect
             defaultValue={ application.student.health.bloodtype? application.student.health.bloodtype : bloodTypesArray[0]}
             onChange={(e) => handleSelect(e, 'studentBloodtype')}
             inputProps={{
               name: 'studentBloodtype',
               id: 'studentBloodtype',
             }}
           >
             {(bloodTypesArray).map((obj, key)=>(
              <option key={key} value={obj}>{obj}</option>
             ))}
           </NativeSelect>
         </FormControl>}
      </Grid>
      <Grid item xs={12} sm={6}>
        {loading? <Skeleton animation="wave" /> :
        <TextField
          required
          error={errors['studentNationalId']? true: false}
          helperText={errorMessages['studentNationalId']? errorMessages['studentNationalId']: null}
          id="studentNationalId"
          name="studentNationalId"
          label="Student National ID"
          fullWidth
          autoComplete="nationalId"
          key={35}
          onChange={(e) => handleTextChange(e, 'studentNationalId')}
          defaultValue={application.student.demographics.nationalid? application.student.demographics.nationalid: ''}
        />}
      </Grid>
      <Grid item xs={12} sm={6}>
        {loading? <Skeleton animation="wave" /> :
        <TextField
          required
          error={errors['studentPassportNumber']? true: false}
          helperText={errorMessages['studentPassportNumber']? errorMessages['studentPassportNumber']: null}
          id="studentPassportNumber"
          name="studentPassportNumber"
          label="Student Passport Number"
          fullWidth
          autoComplete="studentPassportNumber"
          key={35}
          onChange={(e) => handleTextChange(e, 'studentPassportNumber')}
          defaultValue={application.student.demographics.passportid? application.student.demographics.passportid: ''}
        />}
      </Grid>
    </Grid>
  )
}

const AttachmentsForm = ({attachmentsInfoLoading, 
  applicationAttachments, handleFilesSelect, 
  filesUploadProgress, handleFilesUpload, handleFileDownload,
  handleFileRemove, removingFile, handleError, dropZoneKey}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
      <DropzoneArea
        key={dropZoneKey}
        dropzoneClass={classes.dropZoneClass}
        onChange={handleFilesSelect}
        showPreviews={true}
        showPreviewsInDropzone={false}
        filesLimit={15}
        maxFileSize={50000000}
        //useChipsForPreview={true}
        showAlerts={false}
        previewText="Selected Files:"
        previewGridProps={{container: {alignItems:'flex-start', wrap:'wrap'},item:{xs:'auto',sm:'auto',md:'auto',lg:'auto'}}}
        getFileLimitExceedMessage={(limit) => handleError('You can only upload '+limit+ ' files at a time.')}
      />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Button color="primary" variant="outlined" 
        className={classes.uploadButton} 
        onClick={handleFilesUpload} disabled={filesUploadProgress>0?true:false}>UPLOAD SELECTED</Button>
        {filesUploadProgress>0 && <LinearProgress variant="determinate" value={filesUploadProgress} />}
        <List dense={false}>
        {!attachmentsInfoLoading && applicationAttachments.map((obj, key)=>
          (<ListItem button key={key} onClick={()=>handleFileDownload(obj._id)} disabled={removingFile[obj._id]?true:false}>
            <ListItemAvatar>
              <Avatar>
                <DescIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={obj.filename}
              primaryTypographyProps={{ noWrap: true }}
              secondary={obj.contentType + " ("+ Math.floor(obj.length/1024)+" KB)"}
            />
            <ListItemSecondaryAction>
              {removingFile[obj._id] && <CircularProgress />}
              {!removingFile[obj._id] &&
              <IconButton onClick={()=>handleFileRemove(obj._id)} edge="end" aria-label="delete">
                <DeleteIcon />
              </IconButton>}
            </ListItemSecondaryAction>
          </ListItem>

        ))} 
        {attachmentsInfoLoading && <CircularProgress size={50} className={classes.inButtonProgress} />} 
        </List>
      </Grid>
    </Grid>
  )
}

const ReviewPaySubmitForm = ({application, applicationAttachments, handleSelect, loading, studentImageSrc}) =>{
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>

      <FormControl fullWidth className={classes.formControl}>
           <InputLabel shrink={true} htmlFor="applyingSchool">Applying School</InputLabel>
           <NativeSelect
             disabled={loading?true:false}
             onChange={(e) => handleSelect(e, 'applyingSchool')}
             inputProps={{
               name: 'applyingSchool',
               id: 'applyingSchool',
             }}
             defaultValue={application._schoolid?application._schoolid:null}
           >
            <option key={453} value="choose">Choose School</option>
             {(application.schoolsList?application.schoolsList:[]).map((obj, key)=>(
              <option key={key} value={obj.Id}>{obj.Name}</option>
             ))}
           </NativeSelect>
      </FormControl>
      
      </Grid>

      <Grid item xs={12} sm={6}>
        {!application.activeTerms||application.activeTerms.length === 0? <p>No Active Terms Available</p> :
           <FormControl fullWidth className={classes.formControl}>
           <InputLabel shrink={true} htmlFor="applyingTerm">Applying Term</InputLabel>
           <NativeSelect
             disabled={loading?true:false}
             onChange={(e) => handleSelect(e, 'applyingTerm')}
             inputProps={{
               name: 'applyingTerm',
               id: 'applyingTerm',
             }}
             defaultValue={application._termid?application._termid:null}
           >
             <option key={454} value="choose">Choose Term</option>
             {(application.activeTerms?application.activeTerms:[]).map((obj, key)=>(
              <option key={key} value={obj.Id}>{obj.Name}</option>
             ))}
           </NativeSelect>
         </FormControl>}
      </Grid>
      <Grid item xs={12} sm={12}>
        {!application.possibleLevels||application.possibleLevels.length === 0? <p>No Applicable Levels Available</p> :
           <FormControl fullWidth className={classes.formControl}>
           <InputLabel shrink={true} htmlFor="applyingLevel">Perferred Level**</InputLabel>
           <NativeSelect
             disabled={loading?true:false}
             onChange={(e) => handleSelect(e, 'applyingLevel')}
             inputProps={{
               name: 'applyingLevel',
               id: 'applyingLevel',
             }}
             defaultValue={application.preferred_level_path?application.preferred_level_path:null}
           >
             <option key={455} value="none">None</option>
             {(application.possibleLevels?application.possibleLevels:[]).map((obj, key)=>(
              <option key={key} value={obj.Name}>{obj.Name}</option>
             ))}
           </NativeSelect>
         </FormControl>}
      </Grid>     
      {/* <Grid item xs={12} sm={12}>
        You can choose to pay now and get a 10% discount on admission application fees.
        
        <p>Total fees due: $99</p>
        <p>Total fees due if paid online: $89</p>
        
        <Button color='primary'>Pay Now</Button>
        <Button color='default'>Pay Later</Button>

      </Grid> */}

      <Grid item xs={12} sm={12}>
        <ApplicationViewOnlyView applicationAttachments={applicationAttachments} application={application} studentImageSrc={studentImageSrc}/>
      </Grid>
      
    </Grid>
  )
}

const ApplicationViewOnlyView = ({application, applicationAttachments, studentImageSrc}) =>{
  const classes = useStyles();
  return (
    <Fragment>
      <h2>Applicant Information</h2>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl fullWidth>
                <TextField
                    disabled={true}
                    label="Applicant's Full Name (As in ID)"
                    defaultValue={application.guardians[0]._id.name} />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
                <TextField
                    disabled={true}
                    label="Relationship to Student"
                    defaultValue={application.guardians[0].studentrelationship} />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                  <TextField
                      disabled={true}
                      label="Mobile Number"
                      defaultValue={application.guardians[0]._id.mobile} />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                  <TextField
                      disabled={true}
                      label="Applicant Email Address"
                      defaultValue={application.guardians[0]._id.email} />
              </FormControl>
            </Grid>
        </Grid>

        <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                  <TextField
                      disabled={true}
                      label="Gender"
                      defaultValue={application.guardians[0]._id.gender} />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                  <TextField
                      disabled={true}
                      label="Nationality"
                      defaultValue={application.guardians[0]._id.nationality.Name} />
              </FormControl>
            </Grid>
        </Grid>

        <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                  <TextField
                      disabled={true}
                      label="Date of Birth"
                      defaultValue={application.guardians[0]._id.dateofbirth.split("T")[0]} />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                  <TextField
                      disabled={true}
                      label="National ID"
                      defaultValue={application.guardians[0]._id.nationalid} />
              </FormControl>
            </Grid>
        </Grid>

        <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                  <TextField
                      disabled={true}
                      label="Passport ID"
                      defaultValue={application.guardians[0]._id.passportid} />
              </FormControl>
            </Grid>
           
        </Grid>

        <h2>Student Information</h2>

        <Grid container spacing={3}>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <Typography>{application.student.name}'s Official Picture</Typography>
                     <img
                      style={{maxWidth: 200, borderRadius: 10, border: 0}}
                      className={classes.studentAvatar}
                      variant="rounded"
                      src={studentImageSrc}
                      onError= {(e) => { e.target.onerror=null;e.target.src = application.student.demographics.gender==='male'? maleAvatarPlaceholder:femaleAvatarPlaceholder; } }
                      />
                      <h2>{applicationAttachments?applicationAttachments.length:application.attachments.length} Other Attachement{!((applicationAttachments?applicationAttachments.length:application.attachments.length)===1)&&'s'}</h2>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                disabled={true}
                                label="Student's Full Name (As in ID)"
                                defaultValue={application.student.name} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                disabled={true}
                                label="Gender"
                                defaultValue={application.student.demographics.gender} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                disabled={true}
                                label="Date of Birth"
                                defaultValue={application.student.demographics.dateofbirth.split("T")[0]} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                          <TextField
                              disabled={true}
                              label="Blood Type"
                              defaultValue={application.student.health.bloodtype} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                          <TextField
                              disabled={true}
                              label="Nationality"
                              defaultValue={application.student.demographics.nationality.Name} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                          <TextField
                              disabled={true}
                              label="National ID"
                              defaultValue={application.student.demographics.nationalid} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                          <TextField
                              disabled={true}
                              label="Passport ID"
                              defaultValue={application.student.demographics.passportid} />
                      </FormControl>
                    </Grid>
                </Grid>
              
            </Grid>
        </Grid>
        
        
    </Fragment>
  )
}

export default function ApplicationView(props) {
  const [error, setError] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const [errorMessages, setErrorMessages] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [applicationId, setApplicationId] = React.useState(props._applicationid);
  const propsUser = props.user;
  const [application, setApplication] = React.useState({
    status:'New',
    student: {
      demographics: {
      }
    },
    guardians: [{
      _id: propsUser.guardian? propsUser.guardian: {}
    }]
  });
  
  
  const [studentImageSrc, setStudentImageSrc] = React.useState('');
  const [siUploadProgress, setSiUploadProgress] = React.useState(0);
  const [filesUploadProgress, setFilesUploadProgress] = React.useState(0);
  const [selectedDocuments, setSelectedDocuments] = React.useState([]);
  const [applicationAttachments, setApplicationAttachments] = React.useState([]);
  const [attachmentsInfoLoading, setAttachmentsInfoLoading] = React.useState(false);
  const [removingFile, setRemovingFile] = React.useState({});

  const [reviewStep, setReviewStep] = React.useState(false);

  const [dropZoneKey, setDropZoneKey] = React.useState(0);

  const [refreshApplication, setRefreshApplication] = React.useState(false);

  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;
 
  const classes = useStyles();

   
  React.useEffect(()=>{
      setTimeout(()=>{
        if(error !== ''){
          setError('');
        }
      }, 3000);
  },[error]);

  React.useEffect(()=>{

    isMounted.current = true;
    const loadApplication = () => {
        
          setLoading(true);
          setTimeout(()=> {
            axios.post(edusim.api_base_uri+"/api/parents/getExistingApplication", {_applicationid: applicationId},{
              headers: {
                'x-access-token': propsUser.token
              }
            }).then(res => {
              setApplication(res.data);
              setLoading(false);
              setStudentImageSrc(edusim.api_base_uri+'/api/parents/getApplicationStudentImage?token='+propsUser.token+'&_applicationid='+applicationId);              
            }).catch(e => {
              if(e.response){
                  if(isMounted.current){
                    setLoading(false);
                  }
                  if(e.response.status === 403){
                      propsUpdateUser({});
                  }
                  
              }else{
                if(isMounted.current){
                  setLoading(false);
                  setError("Network connection might be lost, ")
                }
              }
                  //console.log(e);
            });
          }, 200);
          
    };

    //load existing application
    if(applicationId)
      loadApplication();
    
    if(refreshApplication){
      setRefreshApplication(false);
    }
    
    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [applicationId, refreshApplication, propsUpdateUser, propsUser]);

  React.useEffect(()=>{
    //console.log(application);
   /*  const setLevelBasedOnDOB = () =>{
      let months = Moment(edusim.admission_school_year_start).diff(application.student.demographics.dateofbirth, "months", true);
      let years = months/12;
      const keys = Object.keys(edusim.admission_level_ages);
      let level = "";
      for(var i=0; i<keys.length; i++){
        if(years >= parseInt(keys[i]))
          level = edusim.admission_level_ages[keys[i]];
      }
      setApplication({...application, level: level});
    } */

    const validateFields = () => {
      
      let applicationValid = true;
      
      if(activeStep === 0){
        if(!application.guardians[0]._id.name){
          applicationValid = false;
          setErrors(errors=> ({...errors, 'parentFullName': true}));
          setErrorMessages(errorMessages => ({...errorMessages, 'parentFullName': 'Required!'}));
        }else if(application.guardians[0]._id.name.length < 5){
          applicationValid = false;
          setErrors(errors=> ({...errors, 'parentFullName': true}));
          setErrorMessages(errorMessages=> ({...errorMessages, 'parentFullName': 'Minimum of 5 characters is required'}));
        }else{
          cancelErrors('parentFullName');
        }

        if(!application.guardians[0]._id.dateofbirth){
          applicationValid = false;
          setErrors(errors=>({...errors, 'parentDOB': true}));
          setErrorMessages(errorMessages=>({...errorMessages, 'parentDOB': 'Required!'}));
        }else if(!validDate(application.guardians[0]._id.dateofbirth)){
          applicationValid = false;
          setErrors(errors=>({...errors, 'parentDOB': true}));
          setErrorMessages(errorMessages=>({...errorMessages, 'parentDOB': 'ISO Date like 1980-10-21'}));
        }else if(!parentIsOfApproperiateAge(application.guardians[0]._id.dateofbirth)){
          applicationValid = false;
          setErrors(errors=>({...errors, 'parentDOB': true}));
          setErrorMessages(errorMessages=>({...errorMessages, 'parentDOB': 'Minimum age to apply is ' + edusim.parent_min_age}));
        }else{
          cancelErrors('parentDOB');
        }

        if(!application.student.name){
          applicationValid = false;
          setErrors(errors=>({...errors, 'studentFullName': true}));
          setErrorMessages(errorMessages=>({...errorMessages, 'studentFullName': 'Required!'}));
        }
        else if(application.student.name.length < 5){
          applicationValid = false;
          setErrors(errors=>({...errors, 'studentFullName': true}));
          setErrorMessages(errorMessages=>({...errorMessages, 'studentFullName': 'Minimum of 5 characters is required'}));
        }else{
          cancelErrors('studentFullName');        
        }
        
        if(!application.student.demographics.dateofbirth){
          applicationValid = false;
          setErrors(errors=>({...errors, 'studentDOB': true}));
          setErrorMessages(errorMessages=>({...errorMessages, 'studentDOB': 'Required!'}));
        }else if(!validDate(application.student.demographics.dateofbirth)){
          applicationValid = false;
          setErrors(errors=>({...errors, 'studentDOB': true}));
          setErrorMessages(errorMessages=>({...errorMessages, 'studentDOB': 'ISO Date like 2012-10-21'}));
        }
        else if(!studentIsOfAcceptableAge(application.student.demographics.dateofbirth)){
          applicationValid = false;
          setErrors(errors=>({...errors, 'studentDOB': true}));
          setErrorMessages(errorMessages=>({...errorMessages, 'studentDOB': 'Age must be between ' + edusim.admission_min_age + ' and ' + edusim.admission_max_age }));
        }else{
          cancelErrors('studentDOB');
          //setLevelBasedOnDOB();
        }

        

     }else if(activeStep === 1){      
      if(!application.guardians[0]._id.nationality){
        applicationValid = false;
        setErrors(errors=>({...errors, 'parentNationality': true}));
        setErrorMessages(errorMessages=>({...errorMessages, 'parentNationality': 'Required!'}));
      }else{
        cancelErrors('parentNationality');
      }

      if(!application.guardians[0]._id.nationalid){
        applicationValid = false;
        setErrors(errors=>({...errors, 'parentNationalId': true}));
        setErrorMessages(errorMessages=>({...errorMessages, 'parentNationalId': 'Required'}));
      }else if(application.guardians[0]._id.nationalid.length < 6){
        applicationValid = false;
        setErrors(errors=>({...errors, 'parentNationalId': true}));
        setErrorMessages(errorMessages=>({...errorMessages, 'parentNationalId': 'Minimum 6 Characters'}));
      }else{
        cancelErrors('parentNationalId');
      }

      if(!application.guardians[0]._id.passportid){
        applicationValid = false;
        setErrors(errors=>({...errors, 'parentPassportNumber': true}));
        setErrorMessages(errorMessages=>({...errorMessages, 'parentPassportNumber': 'Required!'}));
      }else if(application.guardians[0]._id.passportid.length < 4){
        applicationValid = false;
        setErrors(errors=>({...errors, 'parentPassportNumber': true}));
        setErrorMessages(errorMessages=>({...errorMessages, 'parentPassportNumber': 'Minimum 4 Characters!'}));
      }else{
        cancelErrors('parentPassportNumber');
      }

      if(!application.guardians[0]._id.employer){
        applicationValid = false;
        setErrors(errors=>({...errors, 'parentEmployerName': true}));
        setErrorMessages(errorMessages=>({...errorMessages, 'parentEmployerName': 'Required!'}));
      }else if(application.guardians[0]._id.employer.length < 4){
        applicationValid = false;
        setErrors(errors=>({...errors, 'parentEmployerName': true}));
        setErrorMessages(errorMessages=>({...errorMessages, 'parentEmployerName': 'Minimum 4 Characters!'}));
      }else{
        cancelErrors('parentEmployerName');
      }

     }
     else if(activeStep === 2){
      if(!application.student.demographics.nationality){
        applicationValid = false;
        setErrors(errors=>({...errors, 'studentNationality': true}));
        setErrorMessages(errorMessages=>({...errorMessages, 'studentNationality': 'Required!'}));
      }else{
        cancelErrors('studentNationality');
      }

      if(!application.student.demographics.nationalid){
        applicationValid = false;
        setErrors(errors=>({...errors, 'studentNationalId': true}));
        setErrorMessages(errorMessages=>({...errorMessages, 'studentNationalId': 'Required'}));
      }else if(application.student.demographics.nationalid.length < 6){
        applicationValid = false;
        setErrors(errors=>({...errors, 'studentNationalId': true}));
        setErrorMessages(errorMessages=>({...errorMessages, 'studentNationalId': 'Minimum 6 Characters'}));
      }else{
        cancelErrors('studentNationalId');
      }

      if(!application.student.demographics.passportid){
        applicationValid = false;
        setErrors(errors=>({...errors, 'studentPassportNumber': true}));
        setErrorMessages(errorMessages=>({...errorMessages, 'studentPassportNumber': 'Required!'}));
      }else if(application.student.demographics.passportid.length < 4){
        applicationValid = false;
        setErrors(errors=>({...errors, 'studentPassportNumber': true}));
        setErrorMessages(errorMessages=>({...errorMessages, 'studentPassportNumber': 'Minimum 4 Characters!'}));
      }else{
        cancelErrors('studentPassportNumber');
      }

     }

     if(application.validateCallBack)
          application.validateCallBack(applicationValid);
    
    }

    const cancelErrors = (fieldName) => {
      setErrors(errors=>({...errors, [fieldName]: false}));
      setErrorMessages(errorMessages=>({...errorMessages, [fieldName]: null}));
    }

    const parentIsOfApproperiateAge = (dob) => {
      //TODO age at the start of the school year (usually august)
      let months = Moment().diff(dob, "months", true);
      let years = months/12;
      if(years < edusim.parent_min_age){
        return false;
      }
      else{
        return true;
      }
    }

    const studentIsOfAcceptableAge = (dob) => {
      //TODO age at the start of the school year (usually august)
      let months = Moment().diff(dob, "months", true);
      let years = months/12;
      if(years < edusim.admission_min_age || years > edusim.admission_max_age){
        return false;
      }
      else{
        return true;
      }
    }

    const validDate = (date) => {
      //console.log(date);
      //we are only interested in the first 10 charachters of this string
      let checkedDate = date.split("T")[0];
      if (Moment(checkedDate?checkedDate:date, "YYYY-MM-DD", true).isValid()) {
        return true;
      } else {
        return false;
      }
    }

    if(application.validate){
      validateFields();
    }

    if(activeStep===3 && application.getAttachmentsInfo)
      application.getAttachmentsInfo();

    if(reviewStep && application.getSchoolsList)
      application.getSchoolsList();
      
  },[application, activeStep, reviewStep]);

  const handleTextChange = (e, fieldName) => {
    let modGuardians = application.guardians;
    let modStudent = application.student;
    switch (fieldName) {
      case 'parentFullName':
        modGuardians[0]._id.name = e.target.value.trim();
        setApplication({...application, guardians: modGuardians});
        break;
      case 'parentDOB':
        modGuardians[0]._id.dateofbirth = e.target.value.trim();
        setApplication({...application, guardians: modGuardians});
        break;
      case 'studentFullName':
        modStudent.name = e.target.value.trim();
        setApplication({...application, student: modStudent});
        break;
      case 'studentDOB':
        modStudent.demographics.dateofbirth = e.target.value.trim();
        setApplication({...application, student: modStudent});
        break;
      case 'parentNationalId':
        modGuardians[0]._id.nationalid = e.target.value.trim();
        setApplication({...application, guardians: modGuardians});
        break;
      case 'parentPassportNumber':
        modGuardians[0]._id.passportid = e.target.value.trim();
        setApplication({...application, guardians: modGuardians});
        break;
      case 'parentEmployerName':
        modGuardians[0]._id.employer = e.target.value.trim();
        setApplication({...application, guardians: modGuardians});
        break;
      case 'studentNationalId':
        modStudent.demographics.nationalid = e.target.value.trim();
        setApplication({...application, student: modStudent});
        break;
      case 'studentPassportNumber':
        modStudent.demographics.passportid = e.target.value.trim();
        setApplication({...application, student: modStudent});
        break;
      default:
        console.log("default case");
    } 
  }

  const handleSelect = (e, fieldName) => {
    let modGuardians = application.guardians;
    let modStudent = application.student;
    switch (fieldName) {
      case 'studentGender':
        modStudent.demographics.gender = e.target.value;
        setApplication({...application, student: modStudent});
        break;
      case 'parentGender':
        modGuardians[0]._id.gender = e.target.value;
        setApplication({...application, guardians: modGuardians});
        break;
      case 'parentStudentRelationship':
        modGuardians[0].studentrelationship = e.target.value;
        setApplication({...application, guardians: modGuardians});
        break;
      case 'parentMaritalStatus':
        modGuardians[0]._id.maritalstatus = e.target.value;
        setApplication({...application, guardians: modGuardians});
        break;
      case 'studentBloodtype':
        modStudent.health.bloodtype = e.target.value;
        setApplication({...application, student: modStudent});
        break;
      case 'applyingSchool':
        
        if(e.target.value !== 'choose'){
          setApplication({...application, _schoolid: e.target.value});
          _getPossibleTerms(e.target.value);
        }else{
          setApplication({...application, _schoolid: null, activeTerms: [], possibleLevels: []});
        }
        break;
      case 'applyingTerm':
        if(e.target.value !== 'choose'){
          setApplication({...application, _termid: e.target.value});
          _getPossibleLevelsInTerm(e.target.value);
        }else{
          setApplication({...application, _termid: null,  possibleLevels: []});
        }
        break;
      case 'applyingLevel':
        if(e.target.value !== 'none'){
          setApplication({...application, preferred_level_path: e.target.value});
        }else{
          setApplication({...application, preferred_level_path: null});
        }
        break;
      default:
        console.log("default case");
    }
  }

  const handleAutoComplete = (newValue, fieldName) => {
    let modGuardians = application.guardians;
    let modStudent = application.student;
    switch (fieldName) {
      case 'parentNationality':
        modGuardians[0]._id.nationality = newValue;
        setApplication({...application, guardians: modGuardians});
        break;
      case 'studentNationality':
        modStudent.demographics.nationality = newValue;
        setApplication({...application, student: modStudent});
        break;
      default:
        console.log("default case");
    }
  }

  const _uploadSiProgress = (progressEvent) =>{
    const percentFraction = progressEvent.loaded / progressEvent.total;
    const percent = Math.floor(percentFraction * 100); 
    //console.log(percent);
    setSiUploadProgress(percent);
    if(percent >= 100){
      setSiUploadProgress(0);
    }
  }

  const handleImageUpload = (e, fieldName) =>{
    if(fieldName==='studentAvatar' && e.target.files[0]){
      if(e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpeg'){
        //console.log(e.target.files[0].name);
        const fd = new FormData();
        fd.append('studentPhoto', e.target.files[0], e.target.files[0].name);
        fd.append('_applicationid', applicationId);
        axios.post(edusim.api_base_uri+"/api/parents/uploadApplicationStudentImage", fd,{
          headers: {
            'content-type': 'multipart/form-data',
            'x-access-token': propsUser.token
          },
          onUploadProgress: _uploadSiProgress
        }).then(res=>{
          let randNum = Math.floor(Math.random()*1000) + 3;
          setStudentImageSrc(edusim.api_base_uri+"/api/parents/getApplicationStudentImage?token="
          +propsUser.token+"&_applicationid="
          +applicationId+'&rand='
          +randNum);
          //console.log(siUploadProgress);
        }).catch(e=>{
          setError("Could not upload the image at this time. Make sure your session is still active and try again.");
        })
      }else{
        setError("Only jpeg and png files are allowed as images.");
      }
     
    }
    
  }

  const createApplication = ()=> {
    if(propsUser.active&&propsUser.mobile){
      setApplication(app=>({...app, validate:true, validateCallBack: (validApplication)=>{
        if(!validApplication){
          setError('Please fix the required information before proceeding.');
          setApplication((app)=>({...app, validateCallBack: null}));
        }else{
          _createApplication();
        }
      }}));
    }else{
      setError('You must activate your profile email address and mobile to create new applications');
    }
    
  }

  const _createApplication = () => {
    //form the state object
    let creationContext = {
        application: edusim.application,
        version: edusim.version,
        operating_system: 'Browser User Agent'
    }
    let state = {
      studentFullName: application.student.name,
      studentGenderIndex: application.student.demographics.gender === 'female'? 1:0,
      studentDOB: application.student.demographics.dateofbirth,
      parentFullName: application.guardians[0]._id.name,
      parentGenderIndex: application.guardians[0]._id.gender === 'female'? 1: 0,
      parentDOB: application.guardians[0]._id.dateofbirth,
      user: propsUser
    }

    setLoading(true);
    axios.post(edusim.api_base_uri+"/api/parents/createNewApplication", {state: state, creationContext: creationContext},{
      headers: {
        'x-access-token': propsUser.token
      }
    }).then(res => {
      setLoading(false);
      setApplicationId(res.data._id);
      axios.get(edusim.api_base_uri + '/api/getGuardianApplicant', {
        headers: {
          'x-access-token': propsUser.token
        }
      })
      .then(res=>{
        propsUpdateUser({...propsUser, guardian: res.data});
      }).catch((error)=>{
      }).finally(()=>{
      });
    }).catch(e => {
      setLoading(false);
      setError("Cannot complete your request at the moment. Please try again later.");
    });
  }

  const _saveApplication = () =>{

    let state = {
      _applicationid: applicationId,
      studentFullName: application.student.name,
      studentGenderIndex: application.student.demographics.gender === 'female'? 1:0,
      studentDOB: application.student.demographics.dateofbirth,
      studentBloodtype: application.student.health.bloodtype?application.student.health.bloodtype:bloodTypesArray[0],
      studentNationalityCountry: application.student.demographics.nationality,
      studentNationalId: application.student.demographics.nationalid,
      studentPassportNumber: application.student.demographics.passportid,
      parentFullName: application.guardians[0]._id.name,
      parentGenderIndex: application.guardians[0]._id.gender === 'female'? 1: 0,
      parentDOB: application.guardians[0]._id.dateofbirth,
      parentStudentRelationship: application.guardians[0].studentrelationship?application.guardians[0].studentrelationship:application.guardians[0]._id.gender === 'female'?femaleRelationsArray[0]:maleRelationsArray[0],
      parentNationalityCountry: application.guardians[0]._id.nationality,
      parentNationalId: application.guardians[0]._id.nationalid,
      parentPassportNumber: application.guardians[0]._id.passportid,
      parentEmployerName: application.guardians[0]._id.employer,
      parentMaritalStatus: application.guardians[0]._id.maritalstatus,
      user: propsUser
    }

    setLoading(true);
    axios.post(edusim.api_base_uri+"/api/parents/saveExistingApplication", {state: state},{
      headers: {
        'x-access-token': propsUser.token
      }
    }).then(res => {
      setLoading(false);
    }).catch(e => {
      setError("Cannot save the application at this time, please try again later.");
      setLoading(false);
      console.log(e);
    });
    
  }

  const _uploadDocumentsProgress = (progressEvent) =>{
    const percentFraction = progressEvent.loaded / progressEvent.total;
    const percent = Math.floor(percentFraction * 100); 
    setFilesUploadProgress(percent);
    if(percent >= 100){
      setFilesUploadProgress(0);
    }
  }

  const _uploadApplicationDocuments = () =>{
    //console.log("Uploading application documents");

    if(selectedDocuments.length > 0){

    let formData = new FormData();

    selectedDocuments.forEach((file)=>{
      formData.append('applicationDocuments[]', file, file.name);
    });

    formData.append("_applicationid", applicationId);

    axios.post(edusim.api_base_uri+"/api/parents/uploadApplicationDocuments", formData,{
      headers: {
        'content-type': 'multipart/form-data',
        'x-access-token': propsUser.token
      },
      onUploadProgress: _uploadDocumentsProgress
    }).then(res => {
      _getAttachmentsInfo();
      setFilesUploadProgress(0);
      setDropZoneKey(dropZoneKey + 1);
    }).catch(e => {
      if(e.response){
        if(e.response.status === 403){
          setError("Permission Problem - Seems that you might have logged off or your session has expired. Try to log in again.");
        }else if(e.response.status === 501){
          setError("Size Problem - Total uploads should be a maximum of 12 document and images and not more than 50MB combined");
        }
        else{
          setError("General Problem - There seems to be an issue with the API service at the moment. Please try again later.");
        }
      }
      else{
        setError("Network Problem - There seems to be an issue with the connection. Are you connected to the internet?");
      }
      setFilesUploadProgress(0);
      console.log(e);
    });

  }
  }

  const handleFilesSelect = (files) => {
    setSelectedDocuments(files);
  }

  const handleFileSelectError = (msg) => {
    setError(msg);
  }

  const handleFilesUpload = () => {
    _uploadApplicationDocuments();
  }

  const _getAttachmentsInfo = () =>{
    setAttachmentsInfoLoading(true);
    axios.get(edusim.api_base_uri+'/api/parents/getApplicationAttachmentsInfo?_applicationid='+ applicationId, {
      headers: {
        'x-access-token': propsUser.token
      }
    }).then(res => {
     setAttachmentsInfoLoading(false);
     setApplicationAttachments(res.data);
    }).catch(e =>{
      setAttachmentsInfoLoading(false);
      console.log(e)
    });
  }

  const _getSchoolsList = () =>{
    setLoading(true);
    //console.log('getting the schools list');

    axios.get(edusim.api_base_uri+"/api/parents/getSchoolsList",{
      headers: {
        'x-access-token': propsUser.token
      }
    }).then(res => {
      //console.log(res.data);
      setApplication({...application, schoolsList: res.data, getSchoolsList: null});
      setLoading(false);
      if(application._schoolid){
        _getPossibleTerms(application._schoolid);
        if(application._termid){
          _getPossibleLevelsInTerm(application._termid);
        }
      }

    }).catch(e => {
      if(e.response){
          if(isMounted.current){
            setLoading(false);
          }
          if(e.response.status === 403){
              propsUpdateUser({});
          }
          
      }else{
        if(isMounted.current){
          setLoading(false);
          setError("Network connection might be lost, ")
        }
      }
          //console.log(e);
    });
  }

  const _getPossibleLevelsTerms = (schoolid) => {
   
    //console.log('getting the schools list');
    axios.get(edusim.api_base_uri+"/api/parents/getPossibleLevelsTerms/"+schoolid+"/"+application._id,{
      headers: {
        'x-access-token': propsUser.token
      }
    }).then(res => {
      console.log(res.data);
      setApplication(app=>({...app, possibleLevels: res.data.possibleLevels,
        activeTerms: res.data.terms}));
    }).catch(e => {
      if(e.response){
          if(isMounted.current){
            //setLoading(false);
          }
          if(e.response.status === 403){
              propsUpdateUser({});
          }
          
      }else{
        if(isMounted.current){
          setLoading(false);
          setError("Network connection might be lost, ")
        }
      }
          //console.log(e);
    });
  }

  const _getPossibleTerms = (schoolid) => {
   
    //console.log('getting the terms list for the school...');
    axios.get(edusim.api_base_uri+"/api/parents/getPossibleTerms/"+schoolid+"/"+application._id,{
      headers: {
        'x-access-token': propsUser.token
      }
    }).then(res => {
      //console.log(res.data);
      setApplication(app=>({...app,
        activeTerms: res.data.terms}));
    }).catch(e => {
      if(e.response){
          if(isMounted.current){
            //setLoading(false);
          }
          if(e.response.status === 403){
              propsUpdateUser({});
          }
          
      }else{
        if(isMounted.current){
          setLoading(false);
          setError("Network connection might be lost, ")
        }
      }
          //console.log(e);
    });
  }

  const _getPossibleLevelsInTerm = (termid) => {
   
    //console.log('getting the levels list for the term...');
    axios.get(edusim.api_base_uri+"/api/parents/getPossibleLevelsInTerm/"+application._schoolid+"/"+application._id+"/"+termid,{
      headers: {
        'x-access-token': propsUser.token
      }
    }).then(res => {
      console.log(res.data);
      setApplication(app=>({...app,
        possibleLevels: res.data.possibleLevels}));
    }).catch(e => {
      if(e.response){
          if(isMounted.current){
            //setLoading(false);
          }
          if(e.response.status === 403){
              propsUpdateUser({});
          }
          
      }else{
        if(isMounted.current){
          setLoading(false);
          setError("Network connection might be lost, ")
        }
      }
          //console.log(e);
    });
  }

  const handleFileRemove = (fileid) =>{
    _removeUploadedFile(fileid);
  }

  const handleFileDownload = (fileid) => {
    //_getUploadedFileAndDisplay(fileid);
    window.open(edusim.api_base_uri+'/api/parents/getApplicationAttachment?_applicationid='+ applicationId+"&fileid="+fileid
    +"&token="+propsUser.token);
  }

  const removeApplicationAttachment = (fileid) =>{
    let newAttachments = applicationAttachments.filter(e=>e._id!==fileid);
    setApplicationAttachments(newAttachments);
  }

  const _removeUploadedFile = (fileid) =>{
    setRemovingFile(removingFile=> ({...removingFile, [fileid]: true}));
    axios.post(edusim.api_base_uri+'/api/parents/removeApplicationAttachment', {_applicationid: applicationId, _fileid: fileid}, {
      headers: {
        'x-access-token': propsUser.token
      }
    }).then(res => {
      setRemovingFile(removingFile=> ({...removingFile, [fileid]: false}));
      removeApplicationAttachment(fileid);
    }).catch(e =>{
      setRemovingFile(removingFile=> ({...removingFile, [fileid]: false}));
      setError("Cannot remove the file at the moment, please try again later.");
    });
  }

  const submitApplication = () => {
    if(application._schoolid && application._termid){
      setLoading(true);
      axios.post(edusim.api_base_uri+'/api/parents/submitExistingApplication', 
      {_applicationid: applicationId,
      applyingSchool: {Id: application._schoolid},
      applyingTerm: {Id: application._termid},
      applyingLevel: {Name: application.preferred_level_path}}, {
        headers: {
          'x-access-token': propsUser.token
        }
      }).then(res=> {
        setLoading(false);
        setTimeout(()=>setRefreshApplication(true), 500);
      }).catch(e=>{
        setLoading(false);
        console.log(e);
      })
    }else{
      setError("You must select a school and a term.");
    }
    
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <GeneralInfoForm 
        loading={loading} 
        user={props.user} 
        handleTextChange={handleTextChange}
        handleSelect={handleSelect} 
        application={application} 
        errors= {errors}
        errorMessages={errorMessages} />;
      case 1:
        return <ParentInfoForm 
        loading={loading} 
        handleTextChange={handleTextChange} 
        handleSelect={handleSelect} 
        handleAutoComplete={handleAutoComplete}
        application={application} 
        errors= {errors}
        errorMessages={errorMessages}/>;
      case 2:
        return <StudentInfoForm 
        loading={loading} 
        handleTextChange={handleTextChange} 
        handleSelect={handleSelect} 
        handleAutoComplete={handleAutoComplete}
        handleImageUpload={handleImageUpload}
        studentImageSrc={studentImageSrc}
        siUploadProgress={siUploadProgress}
        application={application}
        errors= {errors}
        errorMessages={errorMessages}/>;
      case 3:
        return <AttachmentsForm 
        loading={loading} 
        handleFilesSelect={handleFilesSelect}
        handleFilesUpload={handleFilesUpload}
        handleFileRemove={handleFileRemove}
        handleFileDownload={handleFileDownload}
        removingFile={removingFile}
        filesUploadProgress={filesUploadProgress}
        attachmentsInfoLoading={attachmentsInfoLoading}
        applicationAttachments={applicationAttachments} 
        handleError= {handleFileSelectError}
        dropZoneKey={dropZoneKey}
        />;
      default:
        throw new Error('Unknown step');
    }
  }

  const handleNext = () => {
    if(activeStep === steps.length - 1){
      setReviewStep(true);
      setApplication(app=>({...app, getSchoolsList: _getSchoolsList}));
    }else{
      setReviewStep(false);
      //set the default parent relationship if not chosen
      if(activeStep===1){
        if(!application.guardians[0].studentrelationship){
          let modGuardians = [...application.guardians];
          modGuardians[0].studentrelationship = application.guardians[0]._id.gender==='male'?maleRelationsArray[0]:femaleRelationsArray[0];
          setApplication({...application, guardians: modGuardians});
        }
      }
      //set the application's getattachemnets function if the step is 2 going to 3
      let getAttachmentsInfo = null;
      if(activeStep === 2){
        getAttachmentsInfo = _getAttachmentsInfo;
      }
      setApplication(app=>({...app, getAttachmentsInfo: getAttachmentsInfo, validate:true, validateCallBack: (validApplication)=>{
        if(!validApplication){
          setError('Please fix the required information before proceeding.');
          setApplication((app)=>({...app, validateCallBack: null}));
        }else{
          //console.log('APP OK!');
          _saveApplication();
          setActiveStep(activeStep + 1);
          setApplication((app)=>({...app, validateCallBack: null}));
          setApplication((app)=>({...app, validate: false}));
        }
      }}));
    }

  };

  const handleBack = () => {
    if(reviewStep){
      setReviewStep(false);
    }else{
      setApplication(app=>({...app, validate:true, validateCallBack: (validApplication)=>{
        if(!validApplication){
          setError('Please fix the required information before proceeding.');
          setApplication((app)=>({...app, validateCallBack: null}));
        }else{
          //console.log('APP OK!');
          _saveApplication();
          setActiveStep(activeStep - 1);
          setApplication((app)=>({...app, validateCallBack: null}));
          setApplication((app)=>({...app, validate: false}));
        }
      }}));
    }
  };

  const steps = ['General Information', 'Parent Details', 'Student Details', 'Attachments'];
  
  /* const loadUserGuardian = () =>{
    //if the user is a previous applicant, set the there data
    if(propsUser.guardian){
      let newGuardians = [];
      newGuardians.push(propsUser.guardian);
      setApplication({...application, guardians: newGuardians});
    }
  } */
  
  return (
    
    <Container maxWidth="xl" className={classes.container}>
    
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box>
      {applicationId &&
      <h2 style={{padding:0, margin:0}}> {application.student.name? 'Application for ' + application.student.name : <Skeleton animation="wave" />}</h2>
      }
      </Box>
      <Box marginLeft={2}>
      {application.status && application.status!=='New' &&  
      <Chip label={application.status} style={{backgroundColor:statusColors[application.status], color:"#ffffff"}} />
      }
      </Box>
    </Box>
   
   

    {!applicationId &&
     <h2 style={{padding:0, margin:0}}>Create New Application</h2>
    }
    {(application.status==="New" || application.status==="Returned for More Information") &&
    <Paper className={classes.paper}>
      {(error !== '') &&
        <Alert style={{marginBottom: 10}} severity='error'>
            {error}
        </Alert>
      }

      {applicationId && !reviewStep &&
      <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
      </Stepper>}
      {!reviewStep && getStepContent(activeStep)}
      {reviewStep && <ReviewPaySubmitForm 
        application={application}
        handleSelect={handleSelect}
        loading={loading}
        studentImageSrc={studentImageSrc}
        applicationAttachments={applicationAttachments}
      />}
      {applicationId && !loading &&
      <div className={classes.buttons}>
       
      {activeStep !== 0 &&  (
        <Button onClick={handleBack} className={classes.button}>
          Back
        </Button>
      )}
      
      {!reviewStep &&
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.button}
        >
          {activeStep === steps.length - 1 ? 'Review and Submit' : 'Next'}
        </Button>
      }
      {reviewStep &&
        <Button
          variant="contained"
          color="primary"
          onClick={submitApplication}
          className={classes.button}
        >
          Submit Application
        </Button>
      }
      
      
    </div>
      }

      {!applicationId && !loading &&
      <div className={classes.buttons}>
      <Button
        variant="contained"
        color="primary"
        onClick={createApplication}
        className={classes.button}
      >
        Create Application
      </Button>
      
    </div>
      }

      {loading && 
        <div className={classes.buttons}>
          <CircularProgress style={{marginTop: 20}} size={30} className={classes.avatarProgress} />
        </div>
      }

    </Paper>
    }

    {(application.status!=="New" && application.status!=="Returned for More Information") &&
      <ApplicationViewOnlyView application={application} studentImageSrc={studentImageSrc} />
    }
    </Container>
  );
}