import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { MainListItems/* , secondaryListItems */ } from './listItems';
import Popover from '@material-ui/core/Popover';
import Alert from '@material-ui/lab/Alert';

/* import axios from 'axios';
import edusim from '../config/edusim'; */

import Requests from './Requests';
import Invoices from './Invoices';

import { Router /* Link as ReachLink */ } from "@reach/router";

import StudentsView from "../views/StudentsView";
import StudentView from "../views/StudentView";
import StudentSectionView from "../views/StudentSectionView";
import ApplicationsView from "../views/ApplicationsView";
import ApplicationView from "../views/ApplicationView";
import ProfileView from "../views/ProfileView";

import SignInSide from '../views/SignInSide';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://cacticonsult.com/">
        Cacti Software ™ 
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  notificationsContainer: {
    minWidth: '500px',
    maxWidth: "500px",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  notificationsRoot:{
    width: '100%',
    maxWidth: '55ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Dashboard(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleNotifcationsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorEl(null);
  };

  const notificationsOpen = Boolean(anchorEl);
  const notificationsId = notificationsOpen ? 'simple-popover' : undefined;

  //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [error, setError] = React.useState('');
  /* const [loading, setLoading] = React.useState(false); */
  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;
  const propsUser = props.user;

  React.useEffect(()=>{

    isMounted.current = true;
    
    return () => {
        // executed when unmount
        isMounted.current = false;
    }

  },[propsUpdateUser, propsUser]);

  React.useEffect(()=>{
    setTimeout(()=>{
      if(isMounted.current){
        if(error !== ''){
          setError('');
        }
      }
    }, 3000);
  },[error]);


  return (
    <div className={classes.root}>
      <CssBaseline />
      
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Cacti Software - Parent App
          </Typography>
          <IconButton color="inherit" onClick={handleNotifcationsClick}>
            
            <Badge badgeContent={null} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Popover
            id={notificationsId}
            open={notificationsOpen}
            anchorEl={anchorEl}
            onClose={handleNotificationsClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <NotificationsPane />
          </Popover>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems updateUserInformation={props.updateUserInformation} />
        </List>
        {/* <Divider />
        <List>{secondaryListItems}</List> */}
      </Drawer>

      {/*  Main View */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Router primary={false}>
          
          <MainDash path="/" updateUserInformation={props.updateUserInformation} user={props.user} error={error} setError={setError}  />
          <StudentsView path="/students" updateUserInformation={props.updateUserInformation} user={props.user} />
          <StudentView path="/students/:_studentid" updateUserInformation={props.updateUserInformation} user={props.user} />
          <StudentSectionView path="/students/:_studentid/:_sectionid" updateUserInformation={props.updateUserInformation} user={props.user} />
          <ApplicationsView path="/applications" updateUserInformation={props.updateUserInformation} user={props.user} />
          <ApplicationView path="/applications/new" updateUserInformation={props.updateUserInformation} user={props.user} />
          <ApplicationView path="/applications/:_applicationid" updateUserInformation={props.updateUserInformation} user={props.user} />
          <ProfileView path="/profile" updateUserInformation={props.updateUserInformation} user={props.user} />
          
        </Router>
      </main>
    
    </div>
  );
}

function MainDash(props){
  const classes = useStyles();
  //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return(
    <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {(props.error !== '') &&
            <Grid item xs={12}>
              <Alert style={{marginBottom: 10}} severity='error'>
                  {props.error}
              </Alert>
            </Grid>
            }
            {/* Recent Requests */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Requests setError={props.setError} updateUserInformation={props.updateUserInformation} user={props.user}/>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Invoices setError={props.setError} updateUserInformation={props.updateUserInformation} user={props.user}/>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
  );
}

function NotificationsPane(props){

  const classes = useStyles();

  return(
    <Container className={classes.notificationsContainer}>
      <List className={classes.notificationsRoot}>
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText
            primary="Beta Release 1"
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  Notifications are disabled at the moment
                </Typography>
                {" — You will start receiving notifications here."}
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
      </List>

    </Container>
  );

}