import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LockIcon from '@material-ui/icons/Lock';
//import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ListIcon from '@material-ui/icons/List';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
//import BarChartIcon from '@material-ui/icons/BarChart';
//import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Link } from "@reach/router";

function ListItemLink(props) {
  const { icon, primary, to, selected, setActiveLoc } = props;
  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (

        <Link ref={ref} to={to} {...linkProps} onClick={()=>setActiveLoc(to)}
        //getProps={({ isCurrent }) => {
          // the object returned here is passed to the
          // anchor element's props
         /*  return {
            style: { color: isCurrent?  'red' : 'black'}
          } */
        //}}
        />
      )),
    [to, setActiveLoc],
  );

  return (
    <li>
      <ListItem button component={CustomLink} selected={selected}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

export const MainListItems = (props) =>{
  const [activeLoc, setActiveLoc] = React.useState(window.location.pathname);
  return (
  <div>
    <ListItemLink to="/" primary="Dashboard" icon={<DashboardIcon />} 
    selected={ activeLoc ==='/' ? true:false} setActiveLoc={setActiveLoc}
    />
    <ListItemLink to="/students" primary="My Children" icon={<PeopleIcon />} 
    selected={ activeLoc ==='/students' ? true:false} setActiveLoc={setActiveLoc}
    />
    <ListItemLink to="/applications" primary="Applications" icon={<ListIcon />} 
    selected={ activeLoc ==='/applications' ? true:false} setActiveLoc={setActiveLoc}
    />
    <ListItemLink to="/profile" primary="Profile" icon={<PersonIcon />} 
    selected={ activeLoc ==='/profile' ? true:false} setActiveLoc={setActiveLoc}
    />
    <ListItem button
    onClick={()=>props.updateUserInformation({})}>
      <ListItemIcon>
        <LockIcon />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItem>
  </div>
);
}

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);
