import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Container,
  Paper,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  Typography,
  Chip,
  Box,
  LinearProgress,
  CircularProgress
} from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';

import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputIcon from '@material-ui/icons/Input';
import SeatIcon from '@material-ui/icons/EventSeat';
import SchoolIcon from '@material-ui/icons/School';

import MessagesView from './MessagesView';

import Moment from 'moment';

import maleAvatarPlaceholder from '../imgs/maleAvatarPlaceholder.png';
import femaleAvatarPlaceholder from '../imgs/femaleAvatarPlaceholder.png';

import axios from 'axios';
import edusim from '../config/edusim';

import {DropzoneArea} from 'material-ui-dropzone';
import { Link } from "@reach/router";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  studentAvatar:{
    backgroundColor: 'transparent'
  },
  wrapper: {
    position: 'relative',
  },
  inButtonProgress: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
  },
  uploadButton: {
    width:'100%'
  },
  dropZoneClass:{
    maxHeight: '200px'
  },
  infoHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold
    /* flexBasis: '33.33%',
    flexShrink: 0, */

  },
  infoHeadingBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '25%',
    flexShrink: 0,
  },
  infoBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '75%',
    flexShrink: 0,
  },
  infoSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  enrollmentsListRoot:{
    width: '100%',
    maxWidth: '55ch',
    backgroundColor: theme.palette.background.paper,
  },
  accordianHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordianSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const maleRelationsArray = ["Father", "Grandfather", "Guardian"];
const femaleRelationsArray = ["Mother", "Grandmother", "Guardian"];
const maritalStatusArray = ["Married", "Divorced", "Widowed"];
const bloodTypesArray = ["O-", "O+", "A-", "A+","B-","B+","AB-","AB+"];
const statusColors = edusim.admission_status_colors;

const GeneralInfoSheet = ({loading,student,user}) => {
  const classes = useStyles();

  const studentRelationship = 
  student.guardians&&student.guardians.length>0?student.guardians[student.guardians.findIndex(e=>e._id===user.guardian._id)].studentrelationship:'';
  const studentName = student.name;
  const studentDob = student.demographics.dateofbirth?student.demographics.dateofbirth.toString().substr(0,10):'';
  const studentGender = student.demographics.gender?student.demographics.gender:'';
  const studentNationality = student.demographics.nationality?student.demographics.nationality.Name:"";
  const studentNationalId = student.demographics.nationalid;
 
  return (
    <React.Fragment>

      {loading? <Skeleton animation="wave" /> :
     
      <Typography component="h2" variant="h6" color="primary" gutterBottom>General Information</Typography>}
      
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box className={classes.infoHeadingBox}>
          {loading? <Skeleton animation="wave" /> :
          <Typography className={classes.infoHeading}>Relationship</Typography>}
        </Box>
        <Box className={classes.infoBox} marginLeft={2}>
          {loading? <Skeleton animation="wave" /> :
          <Typography className={classes.infoSecondaryHeading}>{studentRelationship}</Typography>}
        </Box>
      </Box> 
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box className={classes.infoHeadingBox}>
        {loading? <Skeleton animation="wave" /> :
          <Typography className={classes.infoHeading}>Student Name</Typography>}
        </Box>
        <Box className={classes.infoBox} marginLeft={2}>
        {loading? <Skeleton animation="wave" /> :
          <Typography className={classes.infoSecondaryHeading}>{studentName}</Typography>}
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center">
        <Box className={classes.infoHeadingBox}>
        {loading? <Skeleton animation="wave" /> :
          <Typography className={classes.infoHeading}>Date of Birth</Typography>}
        </Box>
        <Box className={classes.infoBox} marginLeft={2}>
        {loading? <Skeleton animation="wave" /> :
          <Typography className={classes.infoSecondaryHeading}>{studentDob}</Typography>}
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center">
        <Box className={classes.infoHeadingBox}>
        {loading? <Skeleton animation="wave" /> :
          <Typography className={classes.infoHeading}>Gender</Typography>}
        </Box>
        <Box className={classes.infoBox} marginLeft={2}>
        {loading? <Skeleton animation="wave" /> :
          <Typography className={classes.infoSecondaryHeading}>{studentGender}</Typography>}
        </Box>
      </Box>
      
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box className={classes.infoHeadingBox}>
        {loading? <Skeleton animation="wave" /> :
          <Typography className={classes.infoHeading}>Nationality</Typography>}
        </Box>
        <Box className={classes.infoBox} marginLeft={2}>
        {loading? <Skeleton animation="wave" /> :
          <Typography className={classes.infoSecondaryHeading}>{studentNationality}</Typography>}
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center">
        <Box className={classes.infoHeadingBox}>
        {loading? <Skeleton animation="wave" /> :
          <Typography className={classes.infoHeading}>National ID</Typography>}
        </Box>
        <Box className={classes.infoBox} marginLeft={2}>
        {loading? <Skeleton animation="wave" /> :
          <Typography className={classes.infoSecondaryHeading}>{studentNationalId}</Typography>}
        </Box>
      </Box>
      
    </React.Fragment>
  )
}

const EnrollmentSelectionSheet = ({loading, enrollments, selectedEnrollmentId, handleEnrollmentSelected, user}) =>{
  const classes = useStyles();
  
  return (
    <React.Fragment>
      {loading? <Skeleton animation="wave" /> :
      <Typography component="h2" variant="h6" color="primary" gutterBottom>Enrollments</Typography>}

      <List component="nav" className={classes.enrollmentsListRoot}>
      {enrollments.length<1&&
      <React.Fragment key={'ZeroEnrollments'}>
         <ListItem>
         <ListItemText 
          primary={'Not Enrolled Yet'} />
         </ListItem>
      </React.Fragment>

      }
      {(loading ? Array.from(new Array(3)) : enrollments).map((obj, key) => (
        <React.Fragment key={key}>
        
        <ListItem
            button
            key={key}
            selected={selectedEnrollmentId === (obj&&obj._id?obj._id:false)}
            onClick={(event) => handleEnrollmentSelected(event, (obj&&obj._id?obj._id:null))}
        >
          
          <ListItemIcon>
          {loading?
            <Skeleton animation="wave" style={{width: '100%'}} />:
            <SeatIcon />}
          </ListItemIcon>
          {loading?
            <Skeleton animation="wave" style={{width: '100%'}} />:
          <ListItemText 
          primary={obj&&obj._termid?obj._termid.name+' '+obj._termid.year:null}
          secondary={obj&&obj._levelid?obj._levelid.name:null} />
          
          } 
        </ListItem>
        </React.Fragment>
        
      ))}
    </List>
    </React.Fragment>
  )
}

const EnrollmentSheet = ({setStudentChatActive, setCurrentChatSections, enrollmentLoading, selectedEnrollmentId, currentEnrollment, student, user}) => {
  const classes = useStyles();

  function ListItemLink(props) {
    const { icon, primary, secondary, to } = props;
    const CustomLink = React.useMemo(
      () =>
        React.forwardRef((linkProps, ref) => (
          <Link ref={ref} to={to} {...linkProps} />
        )),
      [to],
    );
  
    return (
      <li>
        <ListItem button component={CustomLink}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={primary} secondary={secondary} />
        </ListItem>
      </li>
    );
  }

  return (
    <React.Fragment>
      {enrollmentLoading? <Skeleton animation="wave" /> : 
      <Typography component="h2" variant="h6" color="primary" gutterBottom>{selectedEnrollmentId? 'Enrollment Information':'No Enrollment Information Available'}</Typography>}
      <Button onClick={(e)=>{setCurrentChatSections(currentEnrollment.sections); setStudentChatActive(true)}}>Messaging</Button>
    
      {selectedEnrollmentId&& 
      enrollmentLoading? <Skeleton animation="wave" /> :   
      <React.Fragment>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.accordianHeading}>Sections</Typography>
          <Typography className={classes.accordianSecondaryHeading}>Enrolled in {currentEnrollment?currentEnrollment.sections?currentEnrollment.sections.length:0:0} Sections this Term</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List style={{width:'100%'}}> 
          {currentEnrollment&&currentEnrollment.sections?
          (enrollmentLoading ? Array.from(new Array(3)) : currentEnrollment.sections).map((obj, key)=> (
            <ListItemLink key={key} to={"/students/"+student._id+"/"+obj._id}
              primary={obj&&obj._subjectid&&obj._subjectid.name}
              secondary={obj&&obj.instructorName&&(obj.instructorName + ' (' + obj.instructorNickName + ')')}
              icon={<SchoolIcon />}
            /> 
          ))
          :null}
          </List>
        </AccordionDetails>
      </Accordion> 
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.accordianHeading}>Absences</Typography>
          <Typography className={classes.accordianSecondaryHeading}>Absent {currentEnrollment?currentEnrollment.absences?currentEnrollment.absences.length:0:0} Times this Term</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
          {currentEnrollment&&currentEnrollment.absences?
          (enrollmentLoading ? Array.from(new Array(3)) : currentEnrollment.absences).map((obj, key)=> (
            <ListItem key={key}>
              
              <ListItemAvatar>
                <Avatar 
                title={obj&&obj.is_excused?"Excused":"No Excuse"}>
                
                {obj&&obj.is_excused? <SentimentSatisfiedAltIcon /> : <SentimentVeryDissatisfiedIcon />}
                 
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={obj&&obj.date?obj.date.toString().substr(0,10):''}
              />

            </ListItem>
          ))
          :null}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.accordianHeading}>Tardiness</Typography>
          <Typography className={classes.accordianSecondaryHeading}>Tardy {currentEnrollment?currentEnrollment.tardies?currentEnrollment.tardies.length:0:0} Times this Term</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
          {currentEnrollment?currentEnrollment.tardies?
          (enrollmentLoading ? Array.from(new Array(3)) : currentEnrollment.tardies).map((obj, key)=> (
            <ListItem key={key}>
              <ListItemAvatar>
                <Avatar
                title={obj&&obj.is_excused?"Excused":"No Excuse"}>
                {obj&&obj.is_excused? <SentimentSatisfiedAltIcon /> : <SentimentVeryDissatisfiedIcon />}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={obj&&obj.date&&obj.duration?obj.duration+' mins on ' +obj.date.toString().substr(0,10):''}
                secondary={obj&&obj.day_name?obj.day_name+' / '+ obj.period_name + ' / ' + obj.subject_name:''}
              />
            </ListItem>
          ))
          :null:null}
          </List>
        </AccordionDetails>
      </Accordion>
      </React.Fragment>
      }
      
    </React.Fragment>
  )
}

export default function StudentView(props) {
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [studentChatActive, setStudentChatActive] = React.useState(false);
  const [currentChatSections, setCurrentChatSections] = React.useState(false);
  const [generalInfoActive, setGeneralInfoActive] = React.useState(false);

  const [studentId, setStudentId] = React.useState(props._studentid);
  const propsUser = props.user;

  
  
  const [student, setStudent] = React.useState({
    status:'',
    name: '',
    demographics: {nationality:{Name:""}},
    guardians: []
  });

  const [enrollmentSections, setEnrollmentSections] = React.useState([]);

  const [studentImageSrc, setStudentImageSrc] = React.useState('');
  const [siUploadProgress, setSiUploadProgress] = React.useState(0);
  const [filesUploadProgress, setFilesUploadProgress] = React.useState(0);
  const [selectedDocuments, setSelectedDocuments] = React.useState([]);
  const [studentAttachments, setStudentAttachments] = React.useState([]);
  const [attachmentsInfoLoading, setAttachmentsInfoLoading] = React.useState(false);
  const [removingFile, setRemovingFile] = React.useState({});

  const [dropZoneKey, setDropZoneKey] = React.useState(0);

  const [refreshStudent, setRefreshStudent] = React.useState(false);

  const [selectedEnrollmentId, setSelectedEnrollmentId] = React.useState(null);
  const [enrollmentLoading, setEnrollmentLoading] = React.useState(false);
  const [enrollments, setEnrollments] = React.useState([]);
  const [currentEnrollment, setCurrentEnrollment] = React.useState({});

  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;
 
  const classes = useStyles();
   
  React.useEffect(()=>{
      setTimeout(()=>{
        if(error !== ''){
          setError('');
        }
      }, 3000);
  },[error]);

  React.useEffect(()=>{

    isMounted.current = true;
    const loadStudent = () => {
        
          setLoading(true);
          setTimeout(()=> {
            axios.post(edusim.api_base_uri+"/api/parents/getExistingStudent", {_studentid: studentId},{
              headers: {
                'x-access-token': propsUser.token
              }
            }).then(res => {
              //console.log(res.data)
              setStudent(res.data.student);
              setEnrollments(res.data.enrollments);
              (res.data.enrollments.length>0)?setSelectedEnrollmentId(res.data.enrollments[0]._id):setSelectedEnrollmentId(null);
              setLoading(false);
              setStudentImageSrc(edusim.api_base_uri+'/api/parents/getApplicationStudentImage?token='+propsUser.token+'&_applicationid='+res.data.student._applicationid);              
            }).catch(e => {
              if(e.response){
                  if(isMounted.current){
                    setLoading(false);
                  }
                  if(e.response.status === 403){
                      propsUpdateUser({});
                  }
                  
              }else{
                if(isMounted.current){
                  setLoading(false);
                  setError("Network connection might be lost, ")
                }
              }
                  //console.log(e);
            });
          }, 200);
          
    };

    //load existing student
    if(studentId)
      loadStudent();
    
    if(refreshStudent){
      setRefreshStudent(false);
    }
    
    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [studentId, refreshStudent, propsUpdateUser, propsUser]);

  React.useEffect(()=>{
    isMounted.current = true;
    const loadEnrollment = () => {
          setEnrollmentLoading(true);
          setTimeout(()=> {
            axios.post(edusim.api_base_uri+"/api/parents/getStudentEnrollment", {_enrollmentid: selectedEnrollmentId},{
              headers: {
                'x-access-token': propsUser.token
              }
            }).then(res => {
              console.log(res.data)
              setCurrentEnrollment(res.data);
              setEnrollmentLoading(false);
            }).catch(e => {
              if(e.response){
                  if(isMounted.current){
                    setEnrollmentLoading(false);
                  }
              }else{
                if(isMounted.current){
                  setEnrollmentLoading(false);
                  setError("Network connection might be lost, ")
                }
              }
                  //console.log(e);
            });
          }, 200);
          
    };

    //load existing student
    if(selectedEnrollmentId)
      loadEnrollment();
    
    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [selectedEnrollmentId, propsUser]);

  const _uploadDocumentsProgress = (progressEvent) =>{
    const percentFraction = progressEvent.loaded / progressEvent.total;
    const percent = Math.floor(percentFraction * 100); 
    setFilesUploadProgress(percent);
    if(percent >= 100){
      setFilesUploadProgress(0);
    }
  }

  const _uploadStudentDocuments = () =>{
    //console.log("Uploading student documents");

    if(selectedDocuments.length > 0){

    let formData = new FormData();

    selectedDocuments.forEach((file)=>{
      formData.append('studentDocuments[]', file, file.name);
    });

    formData.append("_studentid", studentId);

    axios.post(edusim.api_base_uri+"/api/parents/uploadStudentDocuments", formData,{
      headers: {
        'content-type': 'multipart/form-data',
        'x-access-token': propsUser.token
      },
      onUploadProgress: _uploadDocumentsProgress
    }).then(res => {
      _getAttachmentsInfo();
      setFilesUploadProgress(0);
      setDropZoneKey(dropZoneKey + 1);
    }).catch(e => {
      if(e.response){
        if(e.response.status === 403){
          setError("Permission Problem - Seems that you might have logged off or your session has expired. Try to log in again.");
        }else if(e.response.status === 501){
          setError("Size Problem - Total uploads should be a maximum of 12 document and images and not more than 50MB combined");
        }
        else{
          setError("General Problem - There seems to be an issue with the API service at the moment. Please try again later.");
        }
      }
      else{
        setError("Network Problem - There seems to be an issue with the connection. Are you connected to the internet?");
      }
      setFilesUploadProgress(0);
      console.log(e);
    });

  }
  }

  const handleFilesSelect = (files) => {
    setSelectedDocuments(files);
  }

  const handleFileSelectError = (msg) => {
    setError(msg);
  }

  const handleFilesUpload = () => {
    _uploadStudentDocuments();
  }

  const _getAttachmentsInfo = () =>{
    setAttachmentsInfoLoading(true);
    axios.get(edusim.api_base_uri+'/api/parents/getStudentAttachmentsInfo?_studentid='+ studentId, {
      headers: {
        'x-access-token': propsUser.token
      }
    }).then(res => {
     setAttachmentsInfoLoading(false);
     setStudentAttachments(res.data);
    }).catch(e =>{
      setAttachmentsInfoLoading(false);
      console.log(e)
    });
  }

  const handleFileRemove = (fileid) =>{
    _removeUploadedFile(fileid);
  }

  const handleFileDownload = (fileid) => {
    //_getUploadedFileAndDisplay(fileid);
    window.open(edusim.api_base_uri+'/api/parents/getStudentAttachment?_studentid='+ studentId+"&fileid="+fileid
    +"&token="+propsUser.token);
  }

  const removeStudentAttachment = (fileid) =>{
    let newAttachments = studentAttachments.filter(e=>e._id!==fileid);
    setStudentAttachments(newAttachments);
  }

  const _removeUploadedFile = (fileid) =>{
    setRemovingFile(removingFile=> ({...removingFile, [fileid]: true}));
    axios.post(edusim.api_base_uri+'/api/parents/removeStudentAttachment', {_studentid: studentId, _fileid: fileid}, {
      headers: {
        'x-access-token': propsUser.token
      }
    }).then(res => {
      setRemovingFile(removingFile=> ({...removingFile, [fileid]: false}));
      removeStudentAttachment(fileid);
    }).catch(e =>{
      setRemovingFile(removingFile=> ({...removingFile, [fileid]: false}));
      setError("Cannot remove the file at the moment, please try again later.");
    });
  }

  const handleEnrollmentSelected = (e, enrollmentid)=>{
    //console.log(enrollmentid);
    setSelectedEnrollmentId(enrollmentid);
  }

  const toggleStudentInformation = () =>{
    setGeneralInfoActive(!generalInfoActive);
  }
  return (
    studentChatActive?
    <MessagesView student={student} studentImageSrc={studentImageSrc} setStudentChatActive={setStudentChatActive} currentChatSections={currentChatSections} user={propsUser} />
    :
    <Container maxWidth="xl" className={classes.container}>
    
    <Box>
    {(error !== '') &&
        <Alert style={{marginBottom: 10}} severity='error'>
            {error}
        </Alert>
      }
    </Box>
    
    {(studentId && student.name)?
    !generalInfoActive &&
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box>
        {studentImageSrc&& <Avatar className={classes.largeAvatar} src={studentImageSrc} />}
      </Box>
      <Box marginLeft={2}>
      {
        <h2 style={{padding:0, margin:0}}>{student.name}</h2>
      }
      </Box>
      <Box marginLeft={2}>
      {  
        <Chip label={student.status} style={{backgroundColor:statusColors[student.status], color:"#ffffff"}} />
      }
      </Box>
    </Box>
    :
    <Skeleton width="100%" animation="wave" />}

    <Paper className={classes.paper}>
      <Button onClick={toggleStudentInformation}>{generalInfoActive? 'Hide':'Show'} Student Information</Button>
      {generalInfoActive &&
      <Paper className={classes.paper}>  
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={3}>
              <img
              style={{maxWidth: '100%', maxHeight: '100%', borderRadius: 10, border: 0}}
              className={classes.studentAvatar}
              variant="rounded"
              src={studentImageSrc}
              onError= {(e) => { e.target.onerror=null;e.target.src = student.demographics.gender==='male'? maleAvatarPlaceholder:femaleAvatarPlaceholder; } }
              alt={student.name}
              description={student.name}
              />
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
              <GeneralInfoSheet loading={loading} student={student} user={propsUser} />
          </Grid>
        </Grid>
      </Paper>}

      <Grid container spacing={3}>
      <Grid item xs={12} md={4} lg={4}>
        <Paper className={classes.paper}>
        <EnrollmentSelectionSheet 
          enrollments={enrollments} 
          user={propsUser} 
          loading={loading} 
          selectedEnrollmentId={selectedEnrollmentId} 
          handleEnrollmentSelected={handleEnrollmentSelected} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={8} lg={8}>
        <Paper className={classes.paper}>
        <EnrollmentSheet 
          student={student}
          enrollmentLoading={enrollmentLoading} 
          user={propsUser} 
          selectedEnrollmentId={selectedEnrollmentId} 
          currentEnrollment={currentEnrollment} 
          currentChatSections={currentChatSections}
          setCurrentChatSections={setCurrentChatSections}
          setStudentChatActive={setStudentChatActive} />
        </Paper>
      </Grid>
      </Grid>
    
    </Paper>

    </Container>
    
  );
}