import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import Chip from '@material-ui/core/Chip';
import axios from 'axios';
import edusim from '../config/edusim';
import Skeleton from '@material-ui/lab/Skeleton';


export default function Invoices(props) {
  const [loading, setLoading] = React.useState(false);
  const [invoices, setInvoices] = React.useState([]);
  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;
  const propsUser = props.user;
  const propsSetError = props.setError;
  
  const statusColors = edusim.invoices_status_colors;
  React.useEffect(()=>{

    isMounted.current = true;

    const refreshInvoices = () =>{
        setLoading(true);
        setTimeout(()=> {
            axios.post(edusim.api_base_uri+"/api/parents/getInvoices", {},{
                headers: {
                  'x-access-token': propsUser.token
                }
              }).then(res => {
                setInvoices(res.data);
                setLoading(false);
              }).catch(e => {
                if(e.response){
                    if(isMounted.current){
                        setLoading(false);
                    }
                    if(e.response.status === 403){
                        propsUpdateUser({});
                    }
                }else{
                    if(isMounted.current){
                        setLoading(false);
                        propsSetError("Network connection might be lost! ")
                    }                    
                }
                //console.log(e);
              });
        }, 200);
        
    }

    
    refreshInvoices();

    return () => {
        // executed when unmount
        isMounted.current = false;
    }

  },[propsUpdateUser, propsUser, propsSetError]);

  
  return (
    <React.Fragment>
      <Title>Invoices</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Student Name</TableCell>
            {/* <TableCell>Due Date</TableCell> */}
            <TableCell>Currency</TableCell>
            <TableCell>Amount Due</TableCell>
            <TableCell>Paid Amount</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(loading ? Array.from(new Array(3)):invoices).map((row, key) => (
            row?
            <TableRow key={row._id}>
              <TableCell>{row._studentid.name}</TableCell>
              {/* <TableCell>{row.dueOn.toString().substring(0,10)}</TableCell> */}
              <TableCell>{row.currency}</TableCell>
              <TableCell>{row.net_amount}</TableCell>
              <TableCell>{row.paid_amount}</TableCell>
              <TableCell>
                
                <Chip 
                label={row.voided?'Voided':!row.paid_amount||row.paid_amount<row.net_amount?'Pending':'Paid'} 
                style={{backgroundColor:statusColors[row.voided?'Voided':!row.paid_amount||row.paid_amount<row.net_amount?'Pending':'Paid'], color:"#ffffff"}} 
                />  
              
              </TableCell>
            </TableRow>
            :
            <TableRow key={key}>
            <TableCell><Skeleton variant="rect" width={60} height={20} /></TableCell>
            <TableCell><Skeleton variant="rect" width={60} height={20} /></TableCell>
            <TableCell><Skeleton variant="rect" width={60} height={20} /></TableCell>
            <TableCell><Skeleton variant="rect" width={60} height={20} /></TableCell>
            <TableCell><Skeleton variant="rect" width={60} height={20} /></TableCell>
            <TableCell><Skeleton variant="rect" width={60} height={20} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
     
    </React.Fragment>
  );
}
