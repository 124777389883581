import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
//import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import edusim from '../config/edusim';
import Skeleton from '@material-ui/lab/Skeleton';
//import { Link } from "@reach/router";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';



import Alert from '@material-ui/lab/Alert';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import Box from '@material-ui/core/Box';

//import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';

import maleAvatarPlaceholder from '../imgs/maleAvatarPlaceholder.png';
//import femaleAvatarPlaceholder from '../imgs/femaleAvatarPlaceholder.png';

import Resizer from 'react-image-file-resizer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardRoot: {
    /* height: 350, */
    width: 300,
    paddingBottom: theme.spacing(2)
  },
  media: {
    height: 300
  },
  paper: {
    height: 320,
    width: 270,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  },
  inButtonProgress: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
  },
}));

const statusColors = edusim.profile_status_colors;

function ProfileView(props) {
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [activationCode, setActivationCode] = React.useState(null);
  const [activationCodeValid, setActivationCodeValid] = React.useState(false);
  const [reqMobileNumber, setReqMobileNumber] = React.useState(null);
  const [reqMobileNumberValid, setReqMobileNumberValid] = React.useState(false);
  const [resSMSCode, setResSMSCode] = React.useState(null);
  const [otpValid, setOtpValid] = React.useState(false);
  const [smsSent, setSmsSent] = React.useState(false);
  const [profileImageSrc, setProfileImageSrc] = React.useState(edusim.api_base_uri+'/api/getAvatar?token='+props.user.token)
  const [piUploadProgress, setPiUploadProgress] = React.useState(0);
  
  const classes = useStyles();

  React.useEffect(()=>{
    setTimeout(()=>{
      if(error !== ''){
        setError('');
      }
    }, 3000);
  },[error]);

  const activateAccount = ()=>{
    if(validateActivationCode(activationCode)){
      setLoading(true);
      axios.post(edusim.api_base_uri + '/api/verifyAccount', 
        { 
          activationkey: activationCode, 
        },{
          headers: {
            'x-access-token': props.user.token
          }
        })
        .then(res => {
          props.updateUserInformation({...props.user, active:true, token:res.data.newToken});
        }).catch(e=>{
          if(e.response){
            if(e.response.status === 400){
              
            }
          }
        }).finally(()=>{
          setLoading(false);
        });
    }
  }
  
  const verifyOtp = () =>{
    if(validateOtp(resSMSCode)){
      setLoading(true);
      axios.post(edusim.api_base_uri+"/api/verifyUserMobile", {mobile: reqMobileNumber, otp: resSMSCode},{
        headers: {
          'x-access-token': props.user.token
        }
      }).then(res => {
        //update user state
        props.updateUserInformation({...props.user, mobile:reqMobileNumber});
      }).catch(e=>{
        console.log(e);
        setSmsSent(false);
        setReqMobileNumber("");
        if(e.response){
          if(e.response.status === 400){
            
          }
        }
      }).finally(()=>{
        setLoading(false);
      });
    }
  }

  const _uploadPiProgress = (progressEvent) =>{
    const percentFraction = progressEvent.loaded / progressEvent.total;
    const percent = Math.floor(percentFraction * 100); 
    //console.log(percent);
    setPiUploadProgress(percent);
    if(percent >= 100){
      setPiUploadProgress(0);
    }
  }
  
  const handleImageUpload = (e, fieldName) =>{
   
    if(fieldName==='profileAvatar' && e.target.files[0]){
      if(e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpeg'){
        //console.log(e.target.files[0].name);
        const fd = new FormData();
        const files = [...e.target.files]
        try {
          Resizer.imageFileResizer(
            files[0],
            400,
            400,
            'JPEG',
            100,
            0,
            uri => {
                fd.append('avatar', uri, files[0].name);
                _uploadAvatar(fd);
            },
            'blob',
            200,
            200,
            );
        }   catch(err) {
                setError("Something went wrong preventing image upload at this time.");
        }
      }else{
        setError("Only jpeg and png files are allowed as images.");
      }
    }
  }

  const _uploadAvatar = (fd) =>{
    axios.post(edusim.api_base_uri+"/api/uploadAvatar", fd,{
      headers: {
        'content-type': 'multipart/form-data',
        'x-access-token': props.user.token
      },
      onUploadProgress: _uploadPiProgress
    }).then(res=>{
      let randNum = Math.floor(Math.random()*1000) + 3;
      setProfileImageSrc(edusim.api_base_uri+"/api/getAvatar?token="+props.user.token+'&rand='+randNum);
    }).catch(e=>{
      setError("Could not upload the image at this time. Make sure your session is still active and try again.");
    });
  }

  const validatePhone = (phone) => {
    var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

    if (regex.test(phone)) {
      setReqMobileNumberValid(true);
      return true;
    } else {
      setReqMobileNumberValid(false);
      return false;
    }
  }

  const validateActivationCode = (code) => {
    let regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    if (regex.test(code)) {
      setActivationCodeValid(true);
      return true;
    } else {
      setActivationCodeValid(false);
      return false;
    }
  }

  const validateOtp = (otp) => {
    if(otp.length >= 4){
      setOtpValid(true);
      return true;
    }else{
      setOtpValid(false);
      return false;
    }
  }

  const setMobile = () =>{
    
    if(validatePhone(reqMobileNumber)){
      setLoading(true);
      axios.post(edusim.api_base_uri+"/api/setUserMobile", {mobile: reqMobileNumber},{
        headers: {
          'x-access-token': props.user.token
        }
      }).then(res => {
        setSmsSent(true);
      }).catch(e=>{
        console.log(e);
        if(e.response){
          if(e.response.status === 400){
            
          }
        }
      }).finally(()=>{
        setLoading(false);
      });
    }
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      {(error !== '') &&
        <Alert style={{marginBottom: 10}} severity='error'>
            {error}
        </Alert>
      }
       <input
        accept="image/*"
        style={{display:'none'}}
        id="contained-button-file"
        type="file"
        onChange={(e)=> handleImageUpload(e, 'profileAvatar')}
      />
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box>
        <h2 style={{padding:0, margin:0, flex:1}}>{props.user.realname} Profile</h2>
      </Box>
      <Box>
      
      </Box>
    </Box>
    
    
    <Grid container spacing={2} style={{marginTop: 10}}>
      <Grid item xs={12}>
      <Card className={classes.cardRoot}>
        <CardHeader
          avatar={
            <Avatar aria-label="Application" className={classes.avatar}>
              <PersonIcon />
            </Avatar>
          }
          title={props.user.username}
          subheader={'Primary Guardian Applicant'}
        />
        
        <label htmlFor="contained-button-file">
        <Button style={{padding:0, borderRadius: 10, border:0}} component="span" aria-label="upload picture">
        <CardMedia
          className={classes.media}
          component="img"
          src={profileImageSrc}
          onError= {(e) => { e.target.onerror=null;e.target.src = maleAvatarPlaceholder } }
          title={props.user.realname}

        />
        {<CircularProgress variant="determinate" size={50} value={piUploadProgress} className={classes.inButtonProgress} />}
        </Button>
        </label>
        <CardContent>
        <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-around">
          <Chip label={props.user.active?'Email Active':'Email Inactive'} style={{backgroundColor:statusColors[props.user.active?'Verified':'Unverified'], color:"#ffffff"}} />
          <Chip label={props.user.mobile?'Mobile Active':'Mobile Inactive'} style={{backgroundColor:statusColors[props.user.mobile?'Verified':'Unverified'], color:"#ffffff"}} />
        </Box>
        </CardContent>
        <CardActions disableSpacing>
        <Grid container spacing={2} alignItems="center" justify="center">
          {!props.user.active?
          <Grid item>
          {loading? 
          <Skeleton variant="rect" />
          :
          <TextField 
            id="actCode" 
            label="Activation Code"
            error={!activationCodeValid}
            helperText={!activationCodeValid?'Enter a Valid Activation Code!': null}
            onChange={(e)=> {setActivationCode(e.target.value); validateActivationCode(e.target.value)}}
            /* onKeyPress={(e) => { signInEnterKey(e);}} */
            InputProps={{
              endAdornment: 
              <InputAdornment position="end">
                <IconButton 
                  aria-label="activate"
                  onClick={()=>activateAccount()}
                >
                  <CheckCircleOutlineIcon />
                </IconButton>
              </InputAdornment>,
            }}
            />
          }
          </Grid>
          :null}
          {!smsSent&&!props.user.mobile&&props.user.active?
          <Grid item>
          <TextField 
            id="mobileNumber" 
            label="Mobile Number"
            error={!reqMobileNumberValid}
            helperText={!reqMobileNumberValid?'Enter a Valid International Number!': null}
            onChange={(e)=> {setReqMobileNumber(e.target.value); validatePhone(e.target.value)}}
            InputProps={{
              endAdornment: 
              <InputAdornment position="end">
                <IconButton 
                  aria-label="update"
                  onClick={()=>setMobile()}
                >
                  <CheckCircleOutlineIcon />
                </IconButton>
              </InputAdornment>,
            }}
            />
          </Grid>
          :null}
          {smsSent&&!props.user.mobile&&props.user.active?
          <Grid item>
          <TextField 
            id="smsCode" 
            label="SMS OTP"
            error={!otpValid}
            helperText={!otpValid?'Enter the received OTP': null}
            onChange={(e)=> {setResSMSCode(e.target.value); validateOtp(e.target.value)}}
            InputProps={{
              endAdornment: 
              <InputAdornment position="end">
                <IconButton 
                  aria-label="verify"
                  onClick={()=>verifyOtp()}
                >
                  <CheckCircleOutlineIcon />
                </IconButton>
              </InputAdornment>,
            }}
            />
          </Grid>
          :<Grid item>
          <p>Verified Mobile: {props.user.mobile}</p>
            
          </Grid>}
        </Grid>
        
          
        </CardActions>
      </Card>
      </Grid>
    </Grid>
    
    </Container>

  )
}
 
export default ProfileView;