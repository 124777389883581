import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
//import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import edusim from '../config/edusim';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from "@reach/router";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
//import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import Alert from '@material-ui/lab/Alert';

import Box from '@material-ui/core/Box';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';

import maleAvatarPlaceholder from '../imgs/maleAvatarPlaceholder.png';
import femaleAvatarPlaceholder from '../imgs/femaleAvatarPlaceholder.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardRoot: {
    height: 350,
    width: 270,
  },
  media: {
    maxHeight: 220
  },
  paper: {
    height: 320,
    width: 270,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  }
}));

const statusColors = edusim.admission_status_colors;

function FabLink(props) {
    const { icon, fabLabel, to } = props;
    const CustomLink = React.useMemo(
      () =>
        React.forwardRef((linkProps, ref) => (
  
          <Link ref={ref} to={to} {...linkProps}
          //getProps={({ isCurrent }) => {
            // the object returned here is passed to the
            // anchor element's props
           /*  return {
              style: { color: isCurrent?  'red' : 'black'}
            } */
          //}}
          />
        )),
      [to],
    );
  
    return (
      <Fab size="medium" component={CustomLink} color="primary" aria-label={fabLabel}>
        {icon}
      </Fab>
    );
  }

export default function ApplicationsView(props) {
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [applications, setApplications] = React.useState([]);
  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;
  const propsUser = props.user;
  const [noAppsFoundMessage, setNoAppsFoundMessage] = React.useState("");
  React.useEffect(()=>{

    isMounted.current = true;

    const refreshApplications = () =>{
        setLoading(true);
        setTimeout(()=> {
            axios.post(edusim.api_base_uri+"/api/parents/getExistingApplications", {},{
                headers: {
                  'x-access-token': propsUser.token
                }
              }).then(res => {
                setApplications(res.data);
                if(res.data&&res.data.length===0)
                  setNoAppsFoundMessage("Nothing found. You can add an application by clicking on the + icon above.");
                setLoading(false);
              }).catch(e => {
                if(e.response){
                    if(isMounted.current){
                        setLoading(false);
                    }
                    if(e.response.status === 403){
                        propsUpdateUser({});
                    }
                }else{
                    if(isMounted.current){
                        setLoading(false);
                        setError("Network connection might be lost, ")
                    }                    
                }
                console.log(e);
              });
        }, 200);
        
    }

    refreshApplications();

    return () => {
        // executed when unmount
        isMounted.current = false;
    }

  },[propsUpdateUser, propsUser]);

  React.useEffect(()=>{
    setTimeout(()=>{
      if(isMounted.current){
        if(error !== ''){
          setError('');
        }
      }
    }, 3000);
  },[error]);

  const classes = useStyles();
  return (
    
    <Container maxWidth="xl" className={classes.container}>
    {(error !== '') &&
        <Grid item xs={12}>
          <Alert style={{marginBottom: 10}} severity='error'>
              {error}
          </Alert>
        </Grid>
    }
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box>
        <h2 style={{padding:0, margin:0, flex:1}}>Current Admission Applications</h2>
      </Box>
      <Box marginLeft={2}>
        <FabLink to="/applications/new" icon={<AddIcon />} style={{flex:1}} />
      </Box>
    </Box>
    <Grid container spacing={2} style={{marginTop: 10}}>
      <Grid item xs={12}>
        <Grid container justify="flex-start" alignItems="flex-start" spacing={4}>
          {(loading ? Array.from(new Array(3)) : applications).map((obj, key) => (
            <Grid key={key} item>
              {obj? (
              <Link to={'/applications/'+obj._id} style={{ textDecoration: 'none' }}>
              <Card className={classes.cardRoot}>
                <CardHeader
                  avatar={
                    <Avatar aria-label="Application" className={classes.avatar}>
                      <ListAltSharpIcon />
                    </Avatar>
                  }
                  /* action={
                    <IconButton aria-label="settings">
                      <MoreVertIcon />
                    </IconButton>
                  } */
                  title={obj.student.name}
                  subheader={obj.addedOn.split('T')[0]}
                />
                <CardMedia
                  component="img"
                  className={classes.media}
                  //image=
                  src={edusim.api_base_uri+'/api/parents/getApplicationStudentImage?token='+propsUser.token+'&_applicationid='+obj._id}
                  onError= {(e) => { e.target.onerror=null;e.target.src = obj.student.demographics.gender==='male'? maleAvatarPlaceholder:femaleAvatarPlaceholder; } }
                  title={obj.student.name}
                />
                <CardContent>
                  <Chip label={obj.status} style={{backgroundColor:statusColors[obj.status], color:"#ffffff"}} />
                </CardContent>
              </Card>
              </Link>
              ): 
               <Skeleton variant="rect" width={180} height={220} />
              }
            </Grid>
          ))}
        </Grid>
        {!loading&&(applications.length===0) &&
          <Grid item>
            <h2>{noAppsFoundMessage}</h2>
          </Grid>
        }
      </Grid>
    </Grid>
    </Container>
    
  );
}
