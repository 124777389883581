import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import {
  /* Accordion,
  AccordionSummary,
  AccordionDetails, */
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Container,
  Paper,
  Button,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  /* ListItemIcon, */
  Typography,
  Chip,
  Box,
  /* LinearProgress, */
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl
} from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';

/* import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'; */
import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';
/* import SeatIcon from '@material-ui/icons/EventSeat'; */
import CloseIcon from '@material-ui/icons/Close';
import DescIcon from '@material-ui/icons/Description';
/* import SchoolIcon from '@material-ui/icons/School'; */

/* import MessagesView from './MessagesView';

import Moment from 'moment';

import maleAvatarPlaceholder from '../imgs/maleAvatarPlaceholder.png';
import femaleAvatarPlaceholder from '../imgs/femaleAvatarPlaceholder.png'; */

import axios from 'axios';
import edusim from '../config/edusim';

/* import {DropzoneArea} from 'material-ui-dropzone'; */
import { Link } from "@reach/router";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  containedPaper:{
    margin: theme.spacing(0),
    padding: theme.spacing(2)
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  studentAvatar:{
    backgroundColor: 'transparent'
  },
  wrapper: {
    position: 'relative',
  },
  inButtonProgress: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
  },
  uploadButton: {
    width:'100%'
  },
  dropZoneClass:{
    maxHeight: '200px'
  },
  infoHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold
    /* flexBasis: '33.33%',
    flexShrink: 0, */

  },
  infoHeadingBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '25%',
    flexShrink: 0,
  },
  infoBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '75%',
    flexShrink: 0,
  },
  infoSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  enrollmentsListRoot:{
    width: '100%',
    maxWidth: '55ch',
    backgroundColor: theme.palette.background.paper,
  },
  accordianHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordianSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const statusColors = edusim.attendance_status_colors;
const SECTION_OPTIONS_LIST = ["Sessions", "Assignments", "Grade Summary"];

const SectionSessionsSheet = (props) =>{
  const classes = useStyles();
  const {sessions, student} = props;

  function getNumberofAbsences(){
    let absences = 0;
    sessions&&sessions.map((session)=>{
      session.absences.includes(student._id)&&(absences++);
    });
    return absences;
  }
  function getNumberofTardies(){
    let tardies = 0;
    sessions&&sessions.map((session)=>{
      session&&session.tardies&&session.tardies[student._id]&&(tardies++);
    });
    return tardies;
  }
  return (
    <React.Fragment>
    <Typography>Absent {getNumberofAbsences()} times - Tardy {getNumberofTardies()} times.</Typography>
    <Table>
    <TableHead>
        <TableRow>
            <TableCell>
                Day/Period
            </TableCell>
            <TableCell>
                Title
            </TableCell>
            <TableCell>
                Tags
            </TableCell>
            <TableCell>
                Calendar Day
            </TableCell>
            <TableCell>
                Attendance
            </TableCell>
        </TableRow>
    </TableHead>

    <TableBody>
        {sessions && sessions.map((obj, key) => (
        <TableRow key={key}>
            <TableCell>
                Day {obj.day+1} / Period {obj.period+1}
            </TableCell>
            <TableCell>
                    {obj.title}
            </TableCell>
            <TableCell>
                {obj.tags?.map((tag,key)=>(
                    key===0?tag:(", "+tag)
                ))}
            </TableCell>
            <TableCell>
                {obj.actualSessionDateTime}
            </TableCell>
            <TableCell>
                {obj.absences&&obj.absences.includes(student._id)?
                <Chip label={'Absent'} style={{backgroundColor:statusColors['absent'], color:"#ffffff"}} />
                :
                obj.tardies&&obj.tardies[student._id]?
                <Chip label={'Tardy ('+obj.tardies[student._id]+' mins)'} style={{backgroundColor:statusColors['tardy'], color:"#ffffff"}} />
                :
                <Chip label={'On Time'} style={{backgroundColor:statusColors['ontime'], color:"#ffffff"}} />
              }
            </TableCell>
        </TableRow>
        ))}
    </TableBody>
    </Table>
    </React.Fragment>
  );
}

const SectionAssignmentsSheet = (props) =>{
  const classes = useStyles();
  const {assignments, student, categoryTemplate, intervals, buckets, propsUser} = props;
  const [selectedAssignment, setSelectedAssignment] = React.useState(null);

  const BucketDocumentsView = (props)=>{
    const {bucket} = props;

    const [bucketAttachments, setBucketAttachments] = React.useState([]);
    const [attachmentsInfoLoading, setAttachmentsInfoLoading] = React.useState(false);
    
    const _getAttachmentsInfo = () =>{
      setAttachmentsInfoLoading(true);
      axios.get(edusim.api_base_uri+'/api/parents/assignments/'+selectedAssignment._id+'/buckets/'+bucket._id+'/getBucketAttachmentsInfo', {
        headers: {
          'x-access-token': propsUser.token
        }
      }).then(res => {
       console.log(res.data);
       setAttachmentsInfoLoading(false);
       setBucketAttachments(res.data);
      }).catch(e =>{
        setAttachmentsInfoLoading(false);
        console.log(e)
      });
    }

    const handleFileDownload = (fileid) => {

      window.open(edusim.api_base_uri+'/api/parents/assignments/'+selectedAssignment._id+'/buckets/'+bucket._id+'/getBucketAttachment/'+fileid
      +"?token="+propsUser.token);
    }

    React.useEffect(()=>{
      if(bucket) _getAttachmentsInfo();
    },[bucket]); 

    return (
      <Grid container spacing={3}>
        <List dense={false} style={{width:'100%'}}>
        {!attachmentsInfoLoading && bucketAttachments.map((obj, key)=>
        (<ListItem button key={key} onClick={()=>handleFileDownload(obj._id)}>
            <ListItemAvatar>
            <Avatar>
                <DescIcon />
            </Avatar>
            </ListItemAvatar>
            <ListItemText
            primary={obj.filename}
            primaryTypographyProps={{ noWrap: true }}
            secondary={obj.contentType + " ("+ Math.floor(obj.length/1024)+" KB)"}
            />
        </ListItem>))}
        {attachmentsInfoLoading && <CircularProgress size={50} />} 
        </List>
      </Grid>
    )
    
  }
  
  const AssignmentComponent = (props)=>{
    const {selectedAssignment, setSelectedAssignment, student} = props;
    
    return (
      <React.Fragment>
      <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item>
          <IconButton
            aria-label="Grn"
            onClick={() => setSelectedAssignment(null)}>
            <CloseIcon />
          </IconButton>
          </Grid>
          <Grid item>
            <Typography 
            component="h2" 
            variant="h5" 
            color="primary" 
            gutterBottom>
              {selectedAssignment.name}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3}> 
          <Grid item xs={6}>  
              <FormControl>
                <Typography><b>Grade:</b> {selectedAssignment.earned_points&&selectedAssignment.earned_points[student._id]?selectedAssignment.earned_points[student._id]:"E"} / {selectedAssignment.points}</Typography>
              </FormControl>
          </Grid>
          <Grid item xs={6}>
              <FormControl>
                <Typography><b>Bonus Points:</b> {selectedAssignment.bonus_points}</Typography>
              </FormControl>
          </Grid>
          <Grid item xs={6}>
              <FormControl>
              <Typography><b>Due Date:</b> {selectedAssignment.due_date&&selectedAssignment.due_date.toString().split("T")[0]}</Typography>
              </FormControl>
          </Grid>
          <Grid item xs={6}>
              <FormControl>
              <Typography><b>Due Time:</b> {selectedAssignment.due_date&&selectedAssignment.due_time}</Typography>
              </FormControl>
          </Grid>
          <Grid item xs={12}>
              <FormControl>
              <Typography><b>Instructions:</b> {selectedAssignment.instructions}</Typography>
              </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}> 
        {getStudentBuckets(selectedAssignment._id).map((bucket, key)=>(
            <Grid item xs={12} md={6} lg={6} xl={4}>
            <Card key={key} className={classes.cardRoot}>
            <CardHeader style={{backgroundColor: bucket.bgcolor?bucket.bgcolor:"#ffffff"}}
              avatar={
                <Avatar aria-label="Section" className={classes.avatar}>
                  <ListAltSharpIcon />
                </Avatar>
              }
              title={bucket.name}
              subheader={bucket.attachments.length + " Documents"}
            />
            <CardContent>
              <BucketDocumentsView key={key} bucket={bucket} />
            </CardContent>
        </Card>
        </Grid>
        ))}
        </Grid>    
        </React.Fragment>

    )
  }

  function getCategoryName(_categoryid){
    const foundIndex = categoryTemplate.categories.findIndex(c=>c._id===_categoryid);
    return foundIndex>-1?categoryTemplate.categories[foundIndex].name:"Unassigned";
  }

  function getIntervalName(_intervalid){
    const foundIndex = intervals.findIndex(i=>i._id===_intervalid);
    return foundIndex>-1?intervals[foundIndex].name:"Unassigned";
  }

  function getStudentBuckets(_assignmentid){
    const studentBuckets = buckets.filter(b=>b._assignmentid===_assignmentid&&b.students.includes(student._id));
    return studentBuckets;
  }

  return (
    <React.Fragment>
    {!selectedAssignment?
    <Table>
      <TableHead>
          <TableRow>
              <TableCell><b>Name</b></TableCell>
              <TableCell><b>Points</b> </TableCell>
              <TableCell><b>Due</b></TableCell>
              <TableCell><b>Earned</b></TableCell>
              <TableCell><b>Category</b></TableCell>
              <TableCell><b>Interval</b></TableCell>
              <TableCell><b>Buckets</b></TableCell>
          </TableRow>
      </TableHead>
      <TableBody>
          {assignments && assignments.map((obj, key) => (
              <TableRow key={key}>
                  <TableCell><Button onClick={(e)=>setSelectedAssignment(obj)}>{obj.name}</Button></TableCell>
                  <TableCell>{obj.points}</TableCell>
                  <TableCell>{obj.due_date&&obj.due_date.toString().split("T")[0]} {obj.due_time}</TableCell>
                  <TableCell>{obj.earned_points&&obj.earned_points[student._id]?obj.earned_points[student._id]:"E"}</TableCell>
                  <TableCell>
                    {obj._categoryid ? getCategoryName(obj._categoryid) : 'Unassigned'}
                  </TableCell>
                  <TableCell>
                    {obj._intervalid ? getIntervalName(obj._intervalid) : 'Unassigned'}
                  </TableCell>
                  <TableCell>
                    {getStudentBuckets(obj._id).length} Buckets
                  </TableCell>
              </TableRow>
          ))}
      </TableBody>
    </Table>
    :
     <AssignmentComponent selectedAssignment={selectedAssignment} setSelectedAssignment={setSelectedAssignment} student={student} />
    }
    </React.Fragment>
  );
}

const SectionGSSheet = (props) =>{
  const classes = useStyles();
  const {assignments, student, categoryTemplate, intervals} = props;

  const [assignmentAverages, setAssignmentAverages] = React.useState({});
  const [categoryTotalPoints, setCategoryTotalPoints] = React.useState({});
  const [earnedPointsInCategory, setEarnedPointsInCategory] = React.useState({});
  
  const isMounted = React.useRef(null);

  React.useEffect(()=>{

    isMounted.current = true;

    const loadAssignments = () => {
      assignments.map((assignment, key)=>{
        if(assignment.earned_points && assignment.points){
          const studentPoints = assignment.earned_points[student._id]?assignment.earned_points[student._id]:null;
          if(studentPoints){
            let averageAssignmentScore = (studentPoints/assignment.points)*100;
            setAssignmentAverages((currentValue)=> ({...currentValue, [assignment._id] : averageAssignmentScore}));
            if(assignment._categoryid){
              setCategoryTotalPoints((currentValue)=>(
                {...currentValue, 
                  [assignment._categoryid]: 
                    currentValue[assignment._categoryid]?
                    currentValue[assignment._categoryid]+(assignment.points):
                    assignment.points
                }));
              setEarnedPointsInCategory((currentValue)=>(
                {...currentValue, 
                  [assignment._categoryid]: 
                    currentValue[assignment._categoryid]?
                    currentValue[assignment._categoryid]+studentPoints:
                    studentPoints
                }));
            }
          }
        }

      });
    };

    //load existing section
    if(student&&assignments)
      loadAssignments();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [student, assignments]);

  const assignmentsInCategory = (_categoryid) => {
    return assignments.filter(e=>e._categoryid === _categoryid);
  }

  const categoryAverage = (_categoryid) => {
    return categoryTotalPoints[_categoryid]>0?(earnedPointsInCategory[_categoryid]/categoryTotalPoints[_categoryid])*100:null;
  }

  const categoryIntervalAverage = (_categoryid, _intervalid) => {
    let assignmentsInInterval = assignments.filter(e=>(e._intervalid===_intervalid)&&e._categoryid===_categoryid);
    let assignmentsSumInInterval = 0;
    let assignmentTotalPointsInInterval = 0; 
    assignmentsInInterval.forEach((assignment)=>{
        //old way
        //assignmentsSumInInterval += assignmentAverages[assignment._id]?assignmentAverages[assignment._id]:0;
        //new way
        assignmentsSumInInterval += assignment.earned_points[student._id]?assignment.earned_points[student._id]:0;
        assignmentTotalPointsInInterval += assignment.earned_points[student._id]?assignment.points:0;
    });

    /* return assignmentsInInterval.length>0?
    assignmentsSumInInterval/assignmentsInInterval.length:null; */
    return assignmentTotalPointsInInterval>0?
    (assignmentsSumInInterval/assignmentTotalPointsInInterval)*100:null
  }

  const categoryWeightRatio = (_categoryid) => {
      let foundIndex = categoryTemplate.categories.findIndex(e=>e._id===_categoryid);
      return foundIndex>=0?categoryTemplate.categories[foundIndex].weightRatio:null;
  }

  const assignmentAverage = (_assignmentid) => {
    return formatPercent(assignmentAverages[_assignmentid]);
  }

  const assignmentInterval = (assignment) => {
    return intervals[intervals.findIndex(i=>i._id===assignment._intervalid)];
  }

  const termAverage = () =>{
    let termScore = 0;
    for (const categoryid in categoryTotalPoints){
      if(categoryTotalPoints[categoryid]>0){
        let weightedScore = categoryAverage(categoryid) * categoryWeightRatio(categoryid);
        termScore += weightedScore;
      }
    }
    return formatPercent(termScore);
  }

  const intervalWeightedAverage = (_intervalid) => {
    let assignmentsInInterval = assignments.filter(e=>(e._intervalid===_intervalid));
    let intervalCategoryWeight = {};
    intervalCategoryWeight[_intervalid] = {};
     assignmentsInInterval.forEach((assignment)=>{
      assignment.earned_points&&Object.keys(assignment.earned_points).length>0&&
       (intervalCategoryWeight[_intervalid][assignment._categoryid] = categoryWeightRatio(assignment._categoryid));
     });
     for(const categoryid in intervalCategoryWeight[_intervalid]){
        intervalCategoryWeight[_intervalid]["sum"]?
        intervalCategoryWeight[_intervalid]["sum"]+=intervalCategoryWeight[_intervalid][categoryid]
        :intervalCategoryWeight[_intervalid]["sum"]=intervalCategoryWeight[_intervalid][categoryid];
     }
     let intervalWeightedScore = 0;
     for(const categoryid in intervalCategoryWeight[_intervalid]){
      intervalWeightedScore += categoryIntervalAverage(categoryid, _intervalid) 
                              * (intervalCategoryWeight[_intervalid][categoryid]/intervalCategoryWeight[_intervalid]["sum"]);
     }
     return intervalWeightedScore;
  }

  function getPercent(ratio){
    return (Math.round(((ratio*100) + Number.EPSILON) * 100) / 100);
  }

  function formatPercent(percent){
    return percent?percent.toLocaleString(undefined, {maximumFractionDigits:2})+"%":null
  }


  const TableHeaderCell = withStyles(theme => ({
    root: {
      fontWeight: 'bolder'
    }
  }))(TableCell);

  return (
    <React.Fragment>
    <Grid
      container
      spacing={3}
      direction="row"
      justify="flex-start"
      alignItems="center">
      <Grid item>
      <Typography component="h2" variant="h6" color="primary">Student Grading Summary: {student&&student.name} </Typography>
      </Grid>
    </Grid>
    
    <Grid container 
      direction = "row"
      justify ='flex-start'
      alignItems ="center"
      spacing={1}
      style={{marginBottom:"1em"}}>            
      <Grid item xs={12} sm={12} lg={12} xl={12}>
      <Paper className={classes.containedPaper} style={{backgroundColor: '#fffddb'}}>
      <Typography className="summaryTitle">Term Weighted Average: <strong>{termAverage()}</strong></Typography>
      </Paper>
      </Grid>
      {categoryTemplate&&categoryTemplate.categories&&intervals.map((interval, key)=>(
        <Grid key={key} item xs={12} sm={12} lg={6} xl={6}>
        <Paper className={classes.containedPaper} style={{backgroundColor: '#d0fffe'}}>
          <Typography className="summaryTitle">{interval.short_name} Weighted Average: <strong>{intervalWeightedAverage(interval._id)?formatPercent(intervalWeightedAverage(interval._id)):"N/A"}</strong></Typography>
        </Paper>
        </Grid>
      ))}
      
    </Grid>
    
  {categoryTemplate&&categoryTemplate.categories&&categoryTemplate.categories.map((category, key)=>(
  <Paper key={key} className={classes.paper}>
  
  <Typography variant="h6" style={{marginBottom: "1em"}}>{category.name} ({formatPercent(getPercent(category.weightRatio))} of Total Grade)</Typography>
 
  
  <Grid container 
      direction = "row"
      justify ='flex-start'
      alignItems ="center"
      spacing={1}
      style={{marginBottom:"1em"}}>
    <Grid item xs={12} sm={12} lg={12} xl={12}>
    <Paper className={classes.containedPaper} style={{backgroundColor: '#ffe7d3'}}>
    <Typography>Category Average: <strong>{categoryAverage(category._id)?formatPercent(categoryAverage(category._id)):"N/A"}</strong></Typography>
    </Paper>
    </Grid>
  {intervals.map((interval, key)=>(
    <Grid key={key} item xs={12} sm={12} lg={6} xl={6}>
      <Paper className={classes.containedPaper} style={{backgroundColor: '#eeeeee'}}>
      {interval.short_name} Average: <strong>{categoryIntervalAverage(category._id, interval._id)?formatPercent(categoryIntervalAverage(category._id, interval._id)):"N/A"}</strong>
      </Paper>  
    </Grid> 
  ))}
  </Grid>
  <TableContainer>
    <Table className={classes.table} aria-label="Assignments">
      <TableHead>
        <TableRow>
            <TableHeaderCell align="left">
              Name
            </TableHeaderCell>
            <TableHeaderCell align="left">
              Interval
            </TableHeaderCell>
            <TableHeaderCell align="left">
              Average Score
            </TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {assignmentsInCategory(category._id).map((assignment, akey)=>(
          <TableRow key={akey}>
             <TableCell align="left">
              {assignment.name}
            </TableCell>
            <TableCell align="left">
              {assignmentInterval(assignment)?assignmentInterval(assignment).name + ' (' + assignmentInterval(assignment).short_name + ')':"Unassigned"}
            </TableCell>
            <TableCell align="left">
              {assignmentAverage(assignment._id)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      </Table>
    </TableContainer>
    </Paper>
  ))}

  </React.Fragment>


  );
}

const SectionOptionsList = (props) =>{
  const classes = useStyles();
  const {selectedSectionOption, setSelectedSectionOption} = props;
  
  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>Section Options</Typography>
      <List component="nav" className={classes.enrollmentsListRoot}>
      {SECTION_OPTIONS_LIST.map((option,key)=>(
        <ListItem
          key={key}
          button
          selected={selectedSectionOption === option}
          onClick={(event) => setSelectedSectionOption(option)}
        >
          <ListItemText 
          primary={option}
          /* secondary={} */ />
        </ListItem>
      ))}
    </List>
    </React.Fragment>
  );
}

export default function StudentView(props) {
  const classes = useStyles();
 
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const studentId = props._studentid;
  const sectionId = props._sectionid;
  const propsUser = props.user;
  const propsUpdateUser = props.updateUserInformation;

  const [student, setStudent] = React.useState({});
  const [section, setSection] = React.useState({});
  const [level, setLevel] = React.useState({});
  const [categoryTemplate, setCategoryTemplate] = React.useState({});
  const [sessions, setSessions] = React.useState([]);
  const [assignments, setAssignments] = React.useState([]);
  const [intervals, setIntervals] = React.useState([]);
  const [buckets, setBuckets] = React.useState([]);
  const [studentImageSrc, setStudentImageSrc] = React.useState('');

  const [selectedSectionOption, setSelectedSectionOption] = React.useState("Sessions");
  
  const isMounted = React.useRef(null);
   
  React.useEffect(()=>{
      setTimeout(()=>{
        if(error !== ''){
          setError('');
        }
      }, 3000);
  },[error]);

  React.useEffect(()=>{

    isMounted.current = true;
    const loadSection = () => {
          setLoading(true);
          axios.post(edusim.api_base_uri+"/api/parents/getStudentSection", {_studentid: studentId, _sectionid: sectionId},{
            headers: {
              'x-access-token': propsUser.token
            }
          }).then(res => {
            console.log(res.data);
            setSection(res.data.section);
            setStudent(res.data.student);
            setLevel(res.data.level);
            setAssignments(res.data.assignments);
            setSessions(res.data.sessions);
            setBuckets(res.data.buckets);
            setCategoryTemplate(res.data.categoryTemplate);
            setIntervals(res.data.intervals);
            setStudentImageSrc(edusim.api_base_uri+'/api/parents/getApplicationStudentImage?token='+propsUser.token+'&_applicationid='+res.data.student._applicationid);              
          }).catch(e => {
            if(e.response){
                e.response.status === 403&&propsUpdateUser({});
            }else{
              isMounted.current&&setError("Network connection might be lost")
            }
          }).finally(()=>{
            isMounted.current&&setLoading(false);
          });
    };

    //load existing student
    if(studentId&&sectionId)
      loadSection();
    
    /* if(refreshStudent){
      setRefreshStudent(false);
    } */
    
    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [studentId, sectionId, propsUpdateUser, propsUser]);

  

  return (
    <Container maxWidth="xl" className={classes.container}>
    
    <Box>
    {(error !== '') &&
        <Alert style={{marginBottom: 10}} severity='error'>
            {error}
        </Alert>
      }
    </Box>
    {(studentId && student.name && sectionId && section && level)?
    <Box display="flex" flexDirection="row" alignItems="center">
      <Link to={'/students/'+studentId}>
      <Box>
        {studentImageSrc&& <Avatar className={classes.largeAvatar} src={studentImageSrc} />}
      </Box>
      </Link>
      <Box marginLeft={2}>
      {
        <h2 style={{padding:0, margin:0}}>{student.name}</h2>
      }
      </Box>
      <Box marginLeft={2}>
      {  
        <h2 style={{padding:0, margin:0}}>({level.name + ' ' + section._subjectid.name + ' Taught By ' + section.instructorName})</h2>
      }
      </Box>
    </Box>
    :
    <Skeleton width="100%" animation="wave" />}

      <Grid container spacing={3}>
      <Grid item xs={12} md={4} lg={3}>
        <Paper className={classes.paper}>
          <SectionOptionsList loading={loading} selectedSectionOption={selectedSectionOption} setSelectedSectionOption={setSelectedSectionOption} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <Paper className={classes.paper}>
          {selectedSectionOption==='Sessions'&& <SectionSessionsSheet sessions={sessions} student={student}/> }
          {selectedSectionOption==='Assignments'&& <SectionAssignmentsSheet propsUser={propsUser} assignments={assignments} buckets={buckets} categoryTemplate={categoryTemplate} student={student} intervals={intervals} />}
          {selectedSectionOption==='Grade Summary'&& <SectionGSSheet assignments={assignments} categoryTemplate={categoryTemplate} student={student} intervals={intervals}/>}
         
        </Paper>
      </Grid>
      </Grid>

    </Container>
    
  );
}