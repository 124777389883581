import React from 'react';
//import logo from './logo.svg';
import Dashboard from './mui/Dashboard';
import './App.css';
import {useState} from 'react';
import SignInView from "./views/SignInSide";
import { Router /* Link as ReachLink */ } from "@reach/router";

function App() {

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('@user')) || {}
  );

  const updateUserInformation = (user) =>{
    setUser(user);
    localStorage.setItem("@user", JSON.stringify(user));
  }

  React.useEffect(() => {
    //alert('useEffect Called on mount');
  },[]);

  return (
    <div>
      
    {!user.token &&
      <Router primary={false}>
        <SignInView path="/forgotpassword/:_resettoken" updateUserInformation={updateUserInformation} />
        <SignInView path="/*" updateUserInformation={updateUserInformation} />
      </Router>
      }

    {user.token &&
      <Dashboard updateUserInformation={updateUserInformation} user={user} />}
      
    </div>
  );
}

export default App;