import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
//import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import edusim from '../config/edusim';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from "@reach/router";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
//import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import Alert from '@material-ui/lab/Alert';

import Box from '@material-ui/core/Box';

/* import Fab from '@material-ui/core/Fab'; */
/* import AddIcon from '@material-ui/icons/Add'; */
import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';

import maleAvatarPlaceholder from '../imgs/maleAvatarPlaceholder.png';
import femaleAvatarPlaceholder from '../imgs/femaleAvatarPlaceholder.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardRoot: {
    height: 350,
    width: 270,
  },
  media: {
    maxHeight: 220
  },
  paper: {
    height: 320,
    width: 270,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  }
}));

const statusColors = edusim.admission_status_colors;

export default function StudentsView(props) {
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [students, setStudents] = React.useState([]);
  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;
  const propsUser = props.user;
  const [noStudentsFoundMessage, setNoStudentsFoundMessage] = React.useState("");

  React.useEffect(()=>{

    isMounted.current = true;

    const refreshStudents = () =>{
        setLoading(true);
        setTimeout(()=> {
            axios.post(edusim.api_base_uri+"/api/parents/getStudents", {},{
                headers: {
                  'x-access-token': propsUser.token
                }
              }).then(res => {
                setStudents(res.data);
                if(res.data&&res.data.length===0)
                  setNoStudentsFoundMessage("Nothing found. This list only shows students admitted.");
                setLoading(false);
              }).catch(e => {
                if(e.response){
                    if(isMounted.current){
                        setLoading(false);
                    }
                    if(e.response.status === 403){
                        propsUpdateUser({});
                    }
                }else{
                    if(isMounted.current){
                        setLoading(false);
                        setError("Network connection might be lost, ")
                    }                    
                }
                //console.log(e);
              });
        }, 200);
        
    }

    refreshStudents();

    return () => {
        // executed when unmount
        isMounted.current = false;
    }

  },[propsUpdateUser, propsUser]);

  React.useEffect(()=>{
    setTimeout(()=>{
      if(isMounted.current){
        if(error !== ''){
          setError('');
        }
      }
    }, 3000);
  },[error]);
  const classes = useStyles();
  return (
    
    <Container maxWidth="xl" className={classes.container}>
      {(error !== '') &&
        <Grid item xs={12}>
          <Alert style={{marginBottom: 10}} severity='error'>
              {error}
          </Alert>
        </Grid>
    }
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box>
        <h2 style={{padding:0, margin:0, flex:1}}>My Children</h2>
      </Box>
    </Box>
    <Grid container spacing={2} style={{marginTop: 10}}>
      <Grid item xs={12}>
        <Grid container justify="flex-start" alignItems="flex-start" spacing={4}>
          {(loading ? Array.from(new Array(3)) : students).map((obj, key) => (
            <Grid key={key} item>
              {obj? (
              <Link to={'/students/'+obj._id} style={{ textDecoration: 'none' }}>
              <Card className={classes.cardRoot}>
                <CardHeader
                  avatar={
                    <Avatar aria-label="Student" className={classes.avatar}>
                      <ListAltSharpIcon />
                    </Avatar>
                  }
                  /* action={
                    <IconButton aria-label="settings">
                      <MoreVertIcon />
                    </IconButton>
                  } */
                  title={obj.name}
                  subheader={obj.name}
                />
                <CardMedia
                  component="img"
                  className={classes.media}
                  //image=
                  src={edusim.api_base_uri+'/api/parents/getApplicationStudentImage?token='+propsUser.token+'&_applicationid='+obj._applicationid}
                  onError= {(e) => { e.target.onerror=null;e.target.src = obj.demographics.gender==='male'? maleAvatarPlaceholder:femaleAvatarPlaceholder; } }
                  title={obj.name}
                />
                <CardContent>
              {/*   <Typography variant="body2" color="textSecondary" component="p">
          This impressive paella is a perfect party dish and a fun meal to cook together with your
          guests. Add 1 cup of frozen peas along with the mussels, if you like.
        </Typography> */}
                  <Chip label={obj.status} style={{backgroundColor:statusColors[obj.status], color:"#ffffff"}} />
                </CardContent>
              </Card>
              </Link>
              ): 
               <Skeleton variant="rect" width={180} height={220} />
              }
            </Grid>
          ))}
        </Grid>
        {!loading&&(students.length===0) &&
          <Grid item>
            <h2>{noStudentsFoundMessage}</h2>
          </Grid>
        }
      </Grid>
    </Grid>
    </Container>
    
  );
}